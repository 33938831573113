import React from 'react';
import { useAlert } from 'react-alert';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import useRemoveQueryStringParams from '../../../hooks/useRemoveQueryStringParams';
import { submitConfirmationToken } from './authApiUtils';
import { AuthenticationTokenState } from './Types';

export default function useConfirmationToken(
  updateTokens: (tokens: AuthenticationTokenState) => void,
) {
  const { confirmation_token } = useQueryStringParams();
  const removeQueryStringParams = useRemoveQueryStringParams();
  const removeQueryStringParamsRef = React.useRef(removeQueryStringParams);
  removeQueryStringParamsRef.current = removeQueryStringParams;
  const alert = useAlert();
  const alertRef = React.useRef(alert);
  alertRef.current = alert;
  const updateTokensRef = React.useRef(updateTokens);
  updateTokensRef.current = updateTokens;
  React.useEffect(() => {
    if (confirmation_token) {
      submitConfirmationToken(confirmation_token)
        .then((tokens) => {
          alertRef.current.success('Email address confirmed');
          if (tokens) {
            updateTokensRef.current(tokens);
          }
          removeQueryStringParamsRef.current(['confirmation_token']);
        })
        .catch((err) => {
          alertRef.current.error(err.loginError || 'Could not confirm email address.');
        });
    }
  }, [confirmation_token]);
}
