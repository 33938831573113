/* eslint-disable */
/* istanbul ignore file */

const trackingEnabled =
  process.env.NODE_ENV === 'production' || process.env.NEXT_PUBLIC_ALWAYS_TRACK; // NEXT_PUBLIC_ALWAYS_TRACK is for ease of development

// Initialization
export function initializeHotjar() {
  if (trackingEnabled && process.env.NEXT_PUBLIC_USE_HOTJAR === 'true') {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 1987024, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 'defer';
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
}

export function initializeClarity() {
  if (trackingEnabled) {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', 'h2b0xmwzzl');
  }
}

export function initializeGTM() {
  if (trackingEnabled) {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-5SP4HX8');
  }
}

export function initializeFacebook() {
  if (trackingEnabled) {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = 'defer';
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    window.fbq('init', 678041688998550);
  }
}

export function initializeTwitter() {
  if (trackingEnabled) {
    !(function (e, t, n, s, u, a) {
      e.twq ||
        ((s = e.twq =
          function () {
            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
          }),
        (s.version = '1.1'),
        (s.queue = []),
        (u = t.createElement(n)),
        (u.async = 'defer'),
        (u.src = '//static.ads-twitter.com/uwt.js'),
        (a = t.getElementsByTagName(n)[0]),
        a.parentNode.insertBefore(u, a));
    })(window, document, 'script');
    twq('init', 'o6r7b');
  }
}

export function initializeGtag() {
  if (trackingEnabled) {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-972103332';
    script.async = 'defer';
    document.body.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    window.gtag = gtag;
  }
}

export function initializeBranch(guid) {
  if (trackingEnabled) {
    (function (b, r, a, n, c, h, _, s, d, k) {
      if (!b[n] || !b[n]._q) {
        for (; s < _.length; ) c(h, _[s++]);
        d = r.createElement(a);
        d.async = 'defer';
        d.src = 'https://cdn.branch.io/branch-latest.min.js';
        k = r.getElementsByTagName(a)[0];
        k.parentNode.insertBefore(d, k);
        b[n] = h;
      }
    })(
      window,
      document,
      'script',
      'branch',
      function (b, r) {
        b[r] = function () {
          b._q.push([r, arguments]);
        };
      },
      { _q: [], _v: 1 },
      'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking'.split(
        ' ',
      ),
      0,
    );
    // init Branch
    window.branch.init('key_live_ejLRlvMVoo4j3ew4OW69dbbaCvjSU9xX');
    if (guid) {
      window.branch.setIdentity(guid);
    }
  }
}

export function bingUET() {
  if (trackingEnabled) {
    (function (w, d, t, r, u) {
      var f, n, i;
      (w[u] = w[u] || []),
        (f = function () {
          var o = { ti: '137016101' };
          (o.q = w[u]), (w[u] = new UET(o)), w[u].push('pageLoad');
        }),
        (n = d.createElement(t)),
        (n.src = r),
        (n.async = 'defer'),
        (n.onload = n.onreadystatechange =
          function () {
            var s = this.readyState;
            (s && s !== 'loaded' && s !== 'complete') ||
              (f(), (n.onload = n.onreadystatechange = null));
          }),
        (i = d.getElementsByTagName(t)[0]),
        i.parentNode.insertBefore(n, i);
    })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
  }
}
