import React from 'react';
import { useRouter } from 'next/router';

import dynamic from 'next/dynamic';
import useRouterLanguage from 'hooks/useRouterLanguage';

const LANGUAGE_PROVIDERS: Record<string, React.ComponentType<{ children: React.ReactNode }>> = {
  de: dynamic(() => import('./LanguageProviders/GermanLanguageProvider')),
  fr: dynamic(() => import('./LanguageProviders/FrenchLanguageProvider')),
};

export default function DynamicLanguageProvider({ children }: { children: React.ReactNode }) {
  const language = useRouterLanguage();

  const Provider = LANGUAGE_PROVIDERS[language];
  if (Provider) {
    return <Provider>{children}</Provider>;
  } else {
    return <>{children}</>;
  }
}
