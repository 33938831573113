import React from 'react';

export type Dictionary = (text: string) => string;

export const DictionaryProviderContext = React.createContext<Dictionary>((text: string) => text);

export interface Props {
  children: React.ReactNode;
  translations: Record<string, string>;
}

export default function DictionaryProvider({ children, translations }: Props) {
  const dictionary = React.useCallback((text: string) => translations[text], [translations]);
  return (
    <DictionaryProviderContext.Provider value={dictionary}>
      {children}
    </DictionaryProviderContext.Provider>
  );
}

export function useDictionary() {
  return React.useContext(DictionaryProviderContext);
}
