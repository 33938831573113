import React from 'react';
import { useMutation } from 'react-relay';
import { graphql } from 'react-relay';
import { usePageTrackerMutation } from './__generated__/usePageTrackerMutation.graphql';
import { useRouter } from 'next/router';

const pageViewedMutation = graphql`
  mutation usePageTrackerMutation($input: PageViewedInput!) {
    pageViewed(input: $input)
  }
`;

export default function usePageTracker() {
  const { pathname } = useRouter();
  const [pageViewed] = useMutation<usePageTrackerMutation>(pageViewedMutation);

  React.useEffect(() => {
    pageViewed({ variables: { input: { path: pathname } } });
  }, [pathname, pageViewed]);
}
