import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-au';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import duration from 'dayjs/plugin/duration';

export default function loadDayJs() {
  // const locale = navigator.language?.toLowerCase();
  dayjs.extend(calendar);
  dayjs.extend(localizedFormat);
  dayjs.extend(updateLocale);
  dayjs.extend(duration);
  dayjs.updateLocale('de', {
    calendar: {
      lastDay: '[Gestern um] h:mm A',
      sameDay: '[Heute um] h:mm A',
      nextDay: '[Morgen um] h:mm A',
      lastWeek: '[Letzten] dddd [um] h:mm A',
      nextWeek: 'dddd [um] h:mm A',
      sameElse: 'L',
    },
  });
  dayjs.updateLocale('fr', {
    calendar: {
      lastDay: '[hier à] h:mm A',
      sameDay: "[Aujourd'hui à] h:mm A",
      nextDay: '[Demain à] h:mm A',
      lastWeek: 'dddd [dernier à] h:mm A',
      nextWeek: 'dddd [à] h:mm A',
      sameElse: 'L',
    },
  });
}
