import React, { ReactNode, ReactElement } from 'react';
import classes from './Txt.module.scss';
import clsx from 'clsx';
import useTranslate, { TranslationOptions } from '../../../hooks/useTranslate';
import { translateComplexText } from '../../../utils/complexTextHelper';
import dynamic from 'next/dynamic';

const DynamicMarkdown = dynamic(() => import('components/atoms/Markdown/Markdown'));

interface TxtStyleProps {
  size?: 'small' | 'normal' | 'large' | 'xlarge' | 'xxlarge';
  color?: 'primary' | 'secondary' | 'default';
}

export type Props<T extends React.ElementType = 'div'> = {
  component?: T;
  children: ReactNode;
  className?: string;
  skipTranslation?: boolean;
  translateOptions?: TranslationOptions;
  bold?: boolean;
  asMarkdown?: boolean;
} & React.ComponentProps<T> &
  TxtStyleProps;

function Txt<T extends React.ElementType = 'div'>({
  children,
  component,
  skipTranslation,
  translateOptions,
  color,
  size,
  bold,
  asMarkdown,
  ...rest
}: Props<T>): ReactElement {
  const translate = useTranslate();

  let translation =
    children && !skipTranslation
      ? translateComplexText(children, translate, translateOptions)
      : children;

  translation = asMarkdown ? <DynamicMarkdown>{translation}</DynamicMarkdown> : translation;

  const className = clsx(
    rest.className,
    color && classes[color],
    size && classes[size],
    bold && classes.bold,
    asMarkdown && classes.markdown,
  );

  if (component) {
    const Root = component;
    return (
      <Root {...rest} className={className}>
        {translation}
      </Root>
    );
  } else if (className) {
    return (
      <div {...rest} className={className}>
        {translation}
      </div>
    );
  } else {
    return translation;
  }
}
export default React.memo(Txt);
