import {
  DictionaryProviderContext,
  useDictionary,
} from 'components/templates/LocaisationProvider/DictionaryProvider';
import useRouterLanguage from 'hooks/useRouterLanguage';
import { useRouter } from 'next/router';
import React from 'react';
import { graphql, useMutation } from 'react-relay';
import { getLanguageFromLocale } from 'utils/locale';
import useInTestGroup from '../../../hooks/useInTestGroup';
import { MissingTranslationCollectorMutation } from './__generated__/MissingTranslationCollectorMutation.graphql';
import useIsSsr from 'hooks/useIsSsr';

const onTranslationsUpdatedMutation = graphql`
  mutation MissingTranslationCollectorMutation($input: TranslationsInput!) {
    uploadMissingTranslations(input: $input)
  }
`;

const WAIT_TIME = 5000;

export interface TMissingTranslations {
  [key: string]: Set<string>;
}

export default function MissingTranslationCollector({ children }: { children: React.ReactNode }) {
  const language = useRouterLanguage();
  const [uploadMissingTranslations] = useMutation<MissingTranslationCollectorMutation>(
    onTranslationsUpdatedMutation,
  );
  const missingTranslations = React.useRef<TMissingTranslations>({});
  const requestedTranslations = React.useRef<TMissingTranslations>({});
  const uploadTimeout = React.useRef<NodeJS.Timeout | undefined>();
  const dictionary = useDictionary();
  const isSsr = useIsSsr();

  const collectMissingTranslations = useInTestGroup('upload_missing_translations') && Math.random();
  const router = useRouter();
  const supportedLanguages = Array.from(
    new Set<string>(
      (router.locales || [])
        .filter((locale) => locale !== router.locale)
        .map(getLanguageFromLocale)
        .filter((language) => language !== 'en'),
    ),
  );
  const languageToCheck =
    (collectMissingTranslations &&
      supportedLanguages[Math.floor(Math.random() * supportedLanguages.length)]) ||
    undefined;
  const [translationsToCheck, setTranslationsToCheck] = React.useState<Record<string, string>>({});

  React.useEffect(() => {
    if (languageToCheck) {
      import(`translations/${languageToCheck}_translations.json`)
        .then(setTranslationsToCheck)
        .catch(console.error);
    }
  }, [languageToCheck]);

  const uploadTranslations = React.useCallback(() => {
    const languageChecklist = Object.keys(missingTranslations.current);
    languageChecklist.forEach((languageCheck) => {
      if (missingTranslations.current[languageCheck]?.size > 0) {
        missingTranslations.current[languageCheck] =
          missingTranslations.current[languageCheck] || new Set<string>();
        const input = {
          language: languageCheck,
          originalEnglish: Array.from(missingTranslations.current[languageCheck]),
          appVersion: '2',
        };
        // console.log(
        //   'Missing translations: ',
        //   languageCheck,
        //   missingTranslations.current[languageCheck].size,
        //   Array.from(missingTranslations.current[languageCheck]),
        // );
        uploadMissingTranslations({
          variables: {
            input,
          },
        });
        missingTranslations.current[languageCheck]?.clear();
      }
    });
  }, [uploadMissingTranslations]);

  const handleMissingTranslation = React.useCallback(
    (missingLanguage: string, missingText: string) => {
      missingTranslations.current[missingLanguage] =
        missingTranslations.current[missingLanguage] || new Set<string>();
      requestedTranslations.current[missingLanguage] =
        requestedTranslations.current[missingLanguage] || new Set<string>();

      // Translation was already handled in this session
      if (!isSsr && !requestedTranslations.current[missingLanguage].has(missingText)) {
        missingTranslations.current[missingLanguage].add(missingText);
        requestedTranslations.current[missingLanguage].add(missingText);
        if (uploadTimeout.current) {
          clearTimeout(uploadTimeout.current);
        }
        uploadTimeout.current = setTimeout(uploadTranslations, WAIT_TIME);
      }
    },
    [isSsr, uploadTranslations],
  );
  const wrappedDictionary = React.useCallback(
    (text: string) => {
      const translation = dictionary(text);
      if (!translationsToCheck[text] && languageToCheck) {
        handleMissingTranslation(languageToCheck, text);
      }
      if (!translation) {
        handleMissingTranslation(language, text);
      }
      return translation;
    },
    [dictionary, languageToCheck, translationsToCheck, language, handleMissingTranslation],
  );

  return (
    <DictionaryProviderContext.Provider value={wrappedDictionary}>
      {children}
    </DictionaryProviderContext.Provider>
  );
}
