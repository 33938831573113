import clsx from 'clsx';
import AlertMessage from 'components/molecules/AlertMessage/AlertMessage';
import { DEFAULT_TIMEOUT } from 'components/molecules/AlertMessage/alertMessageTimeout';
import ErrorBoundary from 'components/molecules/ErrorBoundary/ErrorBoundary';
import { HEADER_HEIGHT } from 'components/organisms/Header/Header';
import UserInboxModalWrapper from 'components/organisms/UserInboxModal/UserInboxModalWrapper';
import { SURVEYS } from 'components/pages/pages';
import AuthenticationProvider, {
  useAccessToken,
} from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import DynamicLanguageProvider from 'components/templates/LocaisationProvider/DynamicLanguageProvider';
import MissingTranslationCollector from 'components/templates/LocaisationProvider/MissingTranslationCollector';
import ModalProvider from 'components/templates/ModalProvider/ModalProvider';
import PageContainer from 'components/templates/PageContainer/PageContainer';
import AnalyticsHandler from 'components/templates/TrackingProvider/AnalyticsHandler';
import TrackingProvider from 'components/templates/TrackingProvider/TrackingProvider';
import useQueryStringAlert from 'hooks/useQueryStringAlert';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { Provider as AlertProvider } from 'react-alert';
import { Environment, RelayEnvironmentProvider } from 'react-relay';
import { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { RelayNodeSubscriber } from 'relay/useLiveFragment';
import loadDayJs from 'utils/loadDayJs';
import createRelayEnvironment from '../relay/createRelayEnvironment';
import '../styles/globals.scss';
loadDayJs();

if (typeof window !== 'undefined') {
  if (window.location.pathname?.includes(SURVEYS.path) && window.self !== window.top) {
    // If we are in an iframe we probably have finished the prescreener survey and wanna redirect the parent iframe
    window.parent.location.assign(window.location.href);
  }
}

const RelayWrapper: React.FC<{ children: React.ReactNode; records?: RecordMap; locale?: string }> =
  React.memo(function RelayWrapper({ children, records, locale }) {
    const getAccessToken = useAccessToken();
    useQueryStringAlert();
    const oldRecords = React.useRef(records);
    const [environment] = React.useState(() => {
      const environment = createRelayEnvironment({
        getAccessToken,
        onError: (err) => console.warn(err),
        records,
        locale: locale,
      });
      return environment;
    });
    // We want to update the env on the render thread to not give relay a chance of fetching the data before it was added to the store
    const currentEnvironment = React.useRef(environment);
    if (records !== oldRecords.current) {
      const currentJSONStore = currentEnvironment.current.getStore().getSource().toJSON();
      // TODO: Find a better supported way of merging these stores
      const mergedStore = {
        ...currentJSONStore,
        ...records,
        'client:root': {
          ...currentJSONStore['client:root'],
          ...((records && records['client:root']) || {}),
        },
      };
      oldRecords.current = records;
      const environment = createRelayEnvironment({
        getAccessToken: getAccessToken,
        onError: (err) => console.warn(err),
        records: mergedStore,
        locale: locale,
      });
      currentEnvironment.current = environment;
    }

    return (
      <RelayEnvironmentProvider environment={currentEnvironment.current}>
        <RelayNodeSubscriber />
        {children}
      </RelayEnvironmentProvider>
    );
  });

function MyApp({ Component, pageProps }: AppProps) {
  const { locale } = useRouter();

  return (
    <React.StrictMode>
      <DynamicLanguageProvider>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        </Head>
        <React.Suspense>
          <AlertProvider
            template={AlertMessage}
            containerStyle={{ marginTop: HEADER_HEIGHT, zIndex: 40 }}
            timeout={DEFAULT_TIMEOUT}
          >
            <AuthenticationProvider>
              <React.Suspense>
                <RelayWrapper records={pageProps.relayEnv} locale={locale}>
                  <ModalProvider>
                    <MissingTranslationCollector>
                      <TrackingProvider>
                        <AnalyticsHandler />
                        <PageContainer
                          className={clsx(
                            pageProps.isLandingPage && 'isLandingPage',
                            pageProps.hideFooterCta && 'hideFooterCta',
                            locale && `locale-${locale}`,
                          )}
                        >
                          <React.Suspense>
                            <ErrorBoundary fallback={null}>
                              <UserInboxModalWrapper />
                            </ErrorBoundary>
                          </React.Suspense>
                          <React.Suspense>
                            <Component {...pageProps} />
                          </React.Suspense>
                        </PageContainer>
                      </TrackingProvider>
                    </MissingTranslationCollector>
                  </ModalProvider>
                </RelayWrapper>
              </React.Suspense>
            </AuthenticationProvider>
          </AlertProvider>
        </React.Suspense>
      </DynamicLanguageProvider>
    </React.StrictMode>
  );
}

export default MyApp;
