import React from 'react';
import classes from './LoggedOutHeader.module.scss';
import A from '../../atoms/A/A';
import Txt from '../../atoms/Txt/Txt';
import { LOGIN, SIGN_UP } from '../../pages/pages';
import Button from 'components/atoms/Button/Button';
import clsx from 'clsx';

export interface Props {
  className?: string;
}

const LoggedOutHeader: React.FC<Props> = ({ className }) => {
  return (
    <div className={clsx(classes.root, className)}>
      <Button
        component={A}
        color="primary"
        buttonType="secondary"
        buttonSize="normal"
        to={LOGIN.path}
        prefetch={false}
        className={classes.headerBtn}
      >
        <Txt>Log In</Txt>
      </Button>
      <Button
        component={A}
        color="secondary"
        buttonType="primary"
        buttonSize="normal"
        to={SIGN_UP.path}
        prefetch={false}
        className={classes.headerBtn}
      >
        <Txt>Sign Up</Txt>
      </Button>
    </div>
  );
};

export default LoggedOutHeader;
