import React from 'react';

type ScrollStateTypes = 'noScroll' | 'scrollingDown' | 'scrollingUp';

// NOTE: This hook should only be used once in the application.
export default function useScrollState(): ScrollStateTypes {
  const [scrollState, setScrollState] = React.useState<ScrollStateTypes>('noScroll');

  let prevScroll = React.useRef<number>(0);

  const handleScroll = React.useCallback(() => {
    const currScroll = window.pageYOffset;

    if (currScroll < 5 && scrollState !== 'noScroll') {
      setScrollState('noScroll');
    } else if (currScroll > prevScroll.current + 1 && scrollState !== 'scrollingDown') {
      setScrollState('scrollingDown');
    } else if (currScroll < prevScroll.current - 1 && scrollState !== 'scrollingUp') {
      setScrollState('scrollingUp');
    }

    prevScroll.current = currScroll;
  }, [scrollState]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return scrollState;
}
