/**
 * @generated SignedSource<<bcb62b9f2ffa1b988b161417616cdf8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type useLiveFragmentSubscription$variables = {};
export type useLiveFragmentSubscription$data = {
  readonly dataInvalidated: {
    readonly nodeId: string | null;
    readonly queryName: string | null;
  };
};
export type useLiveFragmentSubscription = {
  variables: useLiveFragmentSubscription$variables;
  response: useLiveFragmentSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DataInvalidatedPayload",
    "kind": "LinkedField",
    "name": "dataInvalidated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nodeId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "queryName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useLiveFragmentSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useLiveFragmentSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "70f6f9f954ff47711e07d841efa90a62",
    "id": null,
    "metadata": {},
    "name": "useLiveFragmentSubscription",
    "operationKind": "subscription",
    "text": "subscription useLiveFragmentSubscription {\n  dataInvalidated {\n    nodeId\n    queryName\n  }\n}\n"
  }
};
})();

(node as any).hash = "70af28b458faad7b88e9b43fd848c9f6";

export default node;
