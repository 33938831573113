import React from 'react';
import Header from '../../organisms/Header/Header';
import classes from './PageContainer.module.scss';
import Footer from 'components/molecules/Footer/Footer';
import clsx from 'clsx';
import useScrollState from './useScrollState';

export interface Props {
  children?: React.ReactNode;
  className?: string;
}

export default function PageContainer({ children, className }: Props): JSX.Element {
  const scrollState = useScrollState();

  return (
    <div className={clsx(classes.root, scrollState, className)}>
      <Header />
      <div className={classes.wrapper}>{children}</div>
      <Footer />
    </div>
  );
}
