import React from 'react';
import classes from './HeroThirdPartyBrand.module.scss';
import Image from 'next/image';
import Txt from 'components/atoms/Txt/Txt';

export interface Props {
  logo: StaticImageData;
  brandName: string;
}

const HeroThirdPartyBrand: React.FC<Props> = ({ logo, brandName }) => {
  return (
    <div className={classes.root}>
      <Txt component="p">In partnership with</Txt>
      <div className={classes.logoContainer}>
        <Image src={logo} alt={brandName} />
      </div>
    </div>
  );
};

export default HeroThirdPartyBrand;
