import React from 'react';
import { isSsr } from './useIsSsr';

const memoryStorage = new Map<string, string>();

export const storage =
  typeof window !== 'undefined'
    ? localStorage
    : {
        getItem: (key: string) => memoryStorage.get(key),
        removeItem: (key: string) => null,
        setItem: (key: string, value: string) => memoryStorage.set(key, value),
      };

export default function useLocalStorageState<T>(
  storageKey: string,
  initialState: T | (() => T),
  options: { storeInitialValue?: boolean; disableInstanceSync?: boolean } = {},
): [T, (newValue: T) => void] {
  const initialStateFactory = React.useCallback(() => {
    const existingState = storage.getItem(storageKey);
    if (existingState) {
      return JSON.parse(existingState);
    }
    const initialValue = initialState instanceof Function ? initialState() : initialState;
    if (options.storeInitialValue) {
      storage.setItem(storageKey, JSON.stringify(initialValue));
    }
    return initialValue;
  }, [initialState, options.storeInitialValue, storageKey]);
  const [state, setState] = React.useState<T>(initialStateFactory);
  const storeState = React.useCallback(
    (newState: T) => {
      setState(newState);
      const newValue = JSON.stringify(newState);
      if (storage.getItem(storageKey) !== newValue) {
        storage.setItem(storageKey, JSON.stringify(newState));
      }
    },
    [storageKey, setState],
  );
  React.useEffect(() => {
    if (!options.disableInstanceSync) {
      const updateState = () => {
        const updated = storage.getItem(storageKey);
        if (updated) {
          setState(JSON.parse(updated));
        }
      };
      window.addEventListener('storage', updateState);
      return () => window.removeEventListener('storage', updateState);
    }
    return () => {};
  }, [setState, storageKey, options.storeInitialValue, options.disableInstanceSync]);
  return [state, storeState];
}
