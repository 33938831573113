import React from 'react';

// We can't just check the user agent and conditionally render components during SSR because this will lead to rehydration issues
export function isSsr(): boolean {
  return typeof window === 'undefined';
}

export default function useIsSsr(): boolean {
  const [isSSR, setIsSSR] = React.useState(true);
  React.useEffect(() => {
    setIsSSR(isSsr());
  }, []);
  return isSSR;
}
