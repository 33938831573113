import clsx from 'clsx';
import React from 'react';
import CenterContainer from '../CenterContainer/CenterContainer';
import classes from './LoadingIndicator.module.scss';
interface Props {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const LoadingIndicator: React.FC<Props> = ({ width, height, color, className }: Props) => {
  const radius = 16;
  return (
    <svg
      className={clsx(classes.root, className)}
      width={width}
      height={height}
      viewBox="0 0 120 30"
      xmlns="http://www.w3.org/2000/svg"
      fill={color || 'currentColor'}
      aria-label="Loading"
    >
      <circle cx="15" cy="15" r={radius + 6}>
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;9;15"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="60" cy="15" r={radius} attributeName="fillOpacity" from="1" to="0.3">
        <animate
          attributeName="r"
          from="9"
          to="9"
          begin="0s"
          dur="0.8s"
          values="9;15;9"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="0.5"
          to="0.5"
          begin="0s"
          dur="0.8s"
          values=".5;1;.5"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="105" cy="15" r={radius + 6}>
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;9;15"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

LoadingIndicator.defaultProps = {
  width: 32,
  color: undefined,
  height: 32,
};

export default LoadingIndicator;
