import React from 'react';
import A from '../../atoms/A/A';
import PageContentWrapper from 'components/templates/Page/PageContentWrapper';
import { TERMS_AND_CONDITIONS, PRIVACY_NOTICE, SIGN_UP } from 'components/pages/pages';
import * as pages from '../../pages/pages';
import SocialLinks from '../../atoms/SocialLinks/SocialLinks';
import Txt from '../../atoms/Txt/Txt';
import classes from './LoggedOutFooter.module.scss';
import clsx from 'clsx';

export interface Props {}

const LoggedOutFooter: React.FC<Props> = () => {
  return (
    <>
      <div
        className={clsx(classes.signUpCtaLinkContainer, 'footer-signup-link', 'logged-out-only')}
      >
        <Txt component={A} to={SIGN_UP.path} className={classes.signUpCtaLink}>
          Sign up for free and start earning
        </Txt>
      </div>
      <footer className={classes.footer}>
        <PageContentWrapper className={classes.footerContainer}>
          <div className={clsx(classes.footerColumn, 'logged-out-only')}>
            <Txt component="h3" className={classes.columnTitle}>
              Join
            </Txt>
            <ul>
              <li>
                <Txt component={A} to={pages.SIGN_UP.path}>
                  Sign Up
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.LOGIN.path}>
                  Login
                </Txt>
              </li>
            </ul>
          </div>
          <div className={clsx(classes.footerColumn, 'logged-in-only')}>
            <Txt component="h3" className={classes.columnTitle}>
              Earn
            </Txt>
            <ul>
              <li>
                <Txt component={A} to={pages.SURVEYS.path}>
                  Surveys
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.CASHBACK.path}>
                  Cashback
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.DEALS.path}>
                  Deals
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.GAMING.path}>
                  Gaming
                </Txt>
              </li>
            </ul>
          </div>
          <div className={classes.footerColumn}>
            <Txt component="h3" className={classes.columnTitle}>
              Learn
            </Txt>
            <ul>
              <li>
                <Txt component={A} to={pages.WHY_QMEE.path}>
                  Why Qmee?
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.HOW_IT_WORKS.path}>
                  How it works
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.SURVEY_TIPS.path}>
                  Survey Tips
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.PRIVACY_PROMISE.path}>
                  Privacy Promise
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.FAQ.path}>
                  FAQ
                </Txt>
              </li>
            </ul>
          </div>
          <div className={classes.footerColumn}>
            <Txt component="h3" className={classes.columnTitle}>
              About
            </Txt>
            <ul>
              <li>
                <Txt component={A} to={pages.ABOUT.path}>
                  About Us
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.CONTACT_US.path}>
                  Contact Us
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.PARTNERS.path}>
                  Partners
                </Txt>
              </li>
              <li>
                <Txt component={A} to={pages.SECURITY.path}>
                  Security
                </Txt>
              </li>
            </ul>
          </div>
          <div className={classes.footerColumn}>
            <Txt component="h3" className={classes.columnTitle}>
              Connect
            </Txt>
            <SocialLinks className={classes.socialLinks} />
          </div>
        </PageContentWrapper>
        <hr className={classes.footerBaseLine} />
        <PageContentWrapper className={classes.footerBase}>
          <span className={classes.left}>© Qmee Ltd {new Date().getFullYear()}</span>
          <div className={classes.right}>
            <Txt component={A} to={TERMS_AND_CONDITIONS.path}>
              Terms of Service
            </Txt>
            <span> | </span>
            <Txt component={A} to={PRIVACY_NOTICE.path}>
              Privacy Notice
            </Txt>
          </div>
        </PageContentWrapper>
      </footer>
    </>
  );
};
export default LoggedOutFooter;
