import React from 'react';
import dynamic from 'next/dynamic';
import { useIsLoggedIn } from 'components/templates/AuthenticationProvider/AuthenticationProvider';

export interface Props {}

const DynamicUserInboxModalMessage = dynamic(() => import('./UserInboxModal'), {
  ssr: false,
});

const UserInboxModalWrapper: React.FC<Props> = () => {
  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn) return <DynamicUserInboxModalMessage />;
  return null;
};

export default UserInboxModalWrapper;
