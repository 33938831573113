import PageConfig from '../templates/Page/PageConfig';

export const ABOUT: PageConfig = {
  name: 'About us',
  description:
    'Qmee is a digital rewards platform that allows users to earn cash rewards for their purchases & opinions, and gives brands a technology solution that gets faster, more accurate answers.',
  path: '/about',
  // Component: React.lazy(() => import('./About/About')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CONTACT_US: PageConfig = {
  name: 'Contact',
  description:
    'Got a question? Contact our dedicated & friendly customer support team - rated excellent for customer support.',
  path: '/contact',
  // Component: React.lazy(() => import('./ContactUs/ContactUs')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};

export const DASHBOARD: PageConfig = {
  name: 'My Qmee',
  path: '/dashboard',
  redirectFrom: ['/'],
  exact: true,
  requiresAuthentication: true,
};

export const LEAGUE: PageConfig = {
  name: 'Qmee League',
  path: '/league',
  exact: true,
  requiresAuthentication: true,
  fullPage: true,
};

export const LEAGUE_PROFILE: PageConfig = {
  name: 'Qmee League Profile',
  path: '/league/profile',
  exact: true,
  requiresAuthentication: true,
  fullPage: true,
};

export const HOW_LEAGUES_WORK: PageConfig = {
  name: 'How Leagues Work',
  path: '/league/how-leagues-work',
  exact: true,
  requiresAuthentication: false,
  fullPage: true,
};

export const AVATAR_BUILDER: PageConfig = {
  name: 'Avatar Builder',
  path: '/league/avatar',
  exact: true,
  requiresAuthentication: true,
  fullPage: true,
};

export const SURVEYS: PageConfig = {
  name: 'Surveys',
  path: '/surveys',
  redirectFrom: ['/users/surveys', '/survey_finish/*'],
  // Component: React.lazy(() => import('./Surveys/Surveys')),
  exact: false,
  requiresAuthentication: true,
};

export const PUBLIC_SURVEYS: PageConfig = {
  name: 'Surveys',
  path: '/surveys/earn-money-online',
  redirectFrom: ['/surveys-earn-money-online'],
  description: 'Earn money online with Qmee surveys',
  // Component: React.lazy(() => import('./PublicSurveys/PublicSurveys')),
  exact: false,
  requiresAuthentication: false,
  shouldIndexPage: true,
};

export const CASHBACK: PageConfig = {
  name: 'Cashback',
  description:
    'Earn cashback on great products and enjoy your wallet being a little bigger at the end of the month.',
  path: '/cashback',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};

export const CASHBACK_AUTOMOTIVE: PageConfig = {
  path: '/cashback/automotive',
  name: 'Automotive Offers',
  description:
    'Earn cashback on automotive products and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_KIDS: PageConfig = {
  path: '/cashback/kids',
  name: 'Baby & Kids Offers',
  description:
    'Earn cashback on products for children and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_BEAUTY: PageConfig = {
  path: '/cashback/beauty',
  name: 'Baby & Kids Offers',
  description:
    'Earn cashback on beauty products and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_BUSINESS: PageConfig = {
  path: '/cashback/business',
  name: 'Baby & Kids Offers',
  description:
    'Earn cashback on business products and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_MEDIA: PageConfig = {
  path: '/cashback/media',
  name: 'Entertainment Offers',
  description:
    'Earn cashback on entertainment and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_FASHION: PageConfig = {
  path: '/cashback/fashion',
  name: 'Fashion Offers',
  description:
    'Earn cashback on fashion and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_FINANCE: PageConfig = {
  path: '/cashback/finance',
  name: 'Finance Offers',
  description:
    'Earn cashback on finance and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_FOOD: PageConfig = {
  path: '/cashback/food',
  name: 'Food Offers',
  description:
    'Earn cashback on food & drink and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_SEASONAL: PageConfig = {
  path: '/cashback/seasonal',
  name: 'Seasonal Offers',
  description: 'Earn amazing cashback on everything you need for this season.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_MEN: PageConfig = {
  path: '/cashback/men',
  name: 'For Men Offers',
  description:
    'Earn cashback on products for men and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_WOMEN: PageConfig = {
  path: '/cashback/women',
  name: 'For Women Offers',
  description:
    'Earn cashback on products for women and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_GAMBLING: PageConfig = {
  path: '/cashback/gambling',
  name: 'Gambling Offers',
  description:
    'Earn cashback on gambling products and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_GIFTS: PageConfig = {
  path: '/cashback/gifts',
  name: 'Gift Offers',
  description:
    'Earn cashback on gifts and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_GAMING: PageConfig = {
  path: '/cashback/category/gaming',
  name: 'Gaming Offers',
  description:
    'Earn cashback on games and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_GROCERIES: PageConfig = {
  path: '/cashback/groceries',
  name: 'Grocery Offers',
  description:
    'Earn cashback on groceries and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_HEALTH: PageConfig = {
  path: '/cashback/health',
  name: 'Health Offers',
  description:
    'Earn cashback on health products and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_HOME: PageConfig = {
  path: '/cashback/home',
  name: 'Home Offers',
  description:
    'Earn cashback on products for the home and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_INSURANCE: PageConfig = {
  path: '/cashback/insurance',
  name: 'Insurance Offers',
  description:
    'Earn cashback on insurance and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_PETS: PageConfig = {
  path: '/cashback/pets',
  name: 'Pets Offers',
  description:
    'Earn cashback on pet products and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_SHOPPING: PageConfig = {
  path: '/cashback/shopping',
  name: 'Shopping Offers',
  description:
    'Earn cashback on shopping and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_SPORT: PageConfig = {
  path: '/cashback/sport',
  name: 'Sport Offers',
  description:
    'Earn cashback on sport and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_TECH: PageConfig = {
  path: '/cashback/tech',
  name: 'Tech Offers',
  description:
    'Earn cashback on tech and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_TRAVEL: PageConfig = {
  path: '/cashback/travel',
  name: 'Travel Offers',
  description:
    'Earn cashback on Travel and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_TODO: PageConfig = {
  path: '/cashback/todo',
  name: 'Todo Offers',
  description:
    'Earn cashback on experiences & activities and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Cashback/Cashback')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};

export const GAMING: PageConfig = {
  name: 'Gaming',
  path: '/gaming',
  redirectFrom: ['/gaming'],
  // Component: React.lazy(() => import('./Gaming/Gaming')),
  description:
    'Earn cashback playing our top games and enjoy your wallet being a little bigger at the end of the month.',
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};

export const EXPLORE: PageConfig = {
  name: 'Explore Qmee',
  path: '/account/explore',
  // Component: React.lazy(() => import('./TaskChecklist/TaskChecklist')),
  exact: true,
  requiresAuthentication: true,
};
export const DEALS: PageConfig = {
  name: 'Deals',
  description:
    'Find deals and money saving coupons on the biggest brands around or just let us show you ones you’ll like.',
  path: '/deals',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_AUTOMOTIVE: PageConfig = {
  path: '/deals/automotive',
  name: 'Automotive Offers',
  description: 'Save on automotive products with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_KIDS: PageConfig = {
  path: '/deals/kids',
  name: 'Baby & Kids Offers',
  description: 'Save on products for children with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_BEAUTY: PageConfig = {
  path: '/deals/beauty',
  name: 'Baby & Kids Offers',
  description: 'Save on beauty products with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_BUSINESS: PageConfig = {
  path: '/deals/business',
  name: 'Baby & Kids Offers',
  description: 'Save on business products with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_MEDIA: PageConfig = {
  path: '/deals/media',
  name: 'Entertainment Offers',
  description: 'Save on entertainment with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_FASHION: PageConfig = {
  path: '/deals/fashion',
  name: 'Fashion Offers',
  description: 'Save on fashion with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_FINANCE: PageConfig = {
  path: '/deals/finance',
  name: 'Finance Offers',
  description: 'Save on finance with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_FOOD: PageConfig = {
  path: '/deals/food',
  name: 'Food Offers',
  description: 'Save on food & drink with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_MEN: PageConfig = {
  path: '/deals/men',
  name: 'For Men Offers',
  description: 'Save on products for men with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_WOMEN: PageConfig = {
  path: '/deals/women',
  name: 'For Women Offers',
  description: 'Save on products for women with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_GAMBLING: PageConfig = {
  path: '/deals/gambling',
  name: 'Gambling Offers',
  description: 'Save on gambling with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_GIFTS: PageConfig = {
  path: '/deals/gifts',
  name: 'Gift Offers',
  description: 'Save on gifts with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_GAMING: PageConfig = {
  path: '/deals/gaming',
  name: 'Gaming Offers',
  description: 'Save on games and enjoy your wallet being a little bigger at the end of the month.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_GROCERIES: PageConfig = {
  path: '/deals/groceries',
  name: 'Grocery Offers',
  description: 'Save on groceries with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_HEALTH: PageConfig = {
  path: '/deals/health',
  name: 'Health Offers',
  description: 'Save on health products with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_HOME: PageConfig = {
  path: '/deals/home',
  name: 'Home Offers',
  description: 'Save on products for the home with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_INSURANCE: PageConfig = {
  path: '/deals/insurance',
  name: 'Insurance Offers',
  description: 'Save on insurance with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_PETS: PageConfig = {
  path: '/deals/pets',
  name: 'Pets Offers',
  description: 'Save on pet products with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_SHOPPING: PageConfig = {
  path: '/deals/shopping',
  name: 'Shopping Offers',
  description: 'Save on shopping with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_SPORT: PageConfig = {
  path: '/deals/sport',
  name: 'Sport Offers',
  description: 'Save on sports with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_TECH: PageConfig = {
  path: '/deals/tech',
  name: 'Tech Offers',
  description: 'Save on tech with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_TRAVEL: PageConfig = {
  path: '/deals/travel',
  name: 'Travel Offers',
  description: 'Save on Travel with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_TODO: PageConfig = {
  path: '/deals/todo',
  name: 'Todo Offers',
  description: 'Save on experiences and activities with great deals and money saving coupons.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEALS_SEASONAL: PageConfig = {
  path: '/deals/seasonal',
  name: 'Seasonal Offers',
  description: 'Get great deals and money saving coupons for everything you need this season.',
  // Component: React.lazy(() => import('./Deals/Deals')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const CASHBACK_OFFER: PageConfig = {
  path: '/cashback/:offerGuid',
  name: 'Cashback',
  // Component: React.lazy(() => import('./OfferLandingPage/OfferLandingPage')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const DEAL_OFFER: PageConfig = {
  path: '/deals/:offerGuid',
  name: 'Deal',
  // Component: React.lazy(() => import('./OfferLandingPage/OfferLandingPage')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};

export const BALANCE: PageConfig = {
  name: 'Account balance',
  path: '/account/balance',
  // Component: React.lazy(() => import('./AccountBalance/AccountBalance')),
  exact: true,
  requiresAuthentication: true,
};
export const PAY_PAL: PageConfig = {
  name: 'PayPal',
  path: '/account/cashouts/paypal',
  // Component: React.lazy(() => import('./PayPal/PayPal')),
  exact: true,
  requiresAuthentication: true,
};
export const VENMO: PageConfig = {
  name: 'Venmo',
  path: '/account/cashouts/venmo',
  // Component: React.lazy(() => import('./Venmo/Venmo')),
  exact: true,
  requiresAuthentication: true,
};
export const CHARITY: PageConfig = {
  name: 'Charity',
  path: '/account/cashouts/charity',
  // Component: React.lazy(() => import('./Charity/Charity')),
  exact: true,
  requiresAuthentication: true,
};
export const CHARITY_LANDING_PAGE: PageConfig = {
  name: 'Charity Landing Page',
  path: '/account/cashouts/charity/:id',
  // Component: React.lazy(() => import('./Charity/Charity')),
  exact: true,
  requiresAuthentication: true,
};
export const GIFT_CARDS: PageConfig = {
  name: 'Gift Cards',
  path: '/account/cashouts/gift-cards',
  // Component: React.lazy(() => import('./GiftCards/GiftCards')),
  exact: true,
  requiresAuthentication: true,
};
export const GIFT_CARD_LANDING_PAGE: PageConfig = {
  name: 'Gift Card Landing Page',
  path: '/account/cashouts/gift-cards/:id',
  // Component: React.lazy(() => import('./GiftCardBrandLandingPage/GiftCardBrandLandingPage')),
  exact: true,
  requiresAuthentication: true,
};
export const WELCOME: PageConfig = {
  path: '/',
  redirectFrom: ['/welcome'],
  name: 'Qmee – Share your Opinion & Shop to earn real cash rewards.',
  description:
    'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
  // Component: React.lazy(() => import('./Welcome/Welcome')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
  fullPage: true,
};
export const SETTINGS: PageConfig = {
  name: 'Settings',
  path: '/account/settings',
  // Component: React.lazy(() => import('./Settings/Settings')),
  exact: true,
  requiresAuthentication: true,
};
export const LOGIN: PageConfig = {
  // We need this page for suspicious login links to work
  name: 'Login',
  path: '/login',
  // Component: React.lazy(() => import('./Login/Login')),
  exact: true,
  requiresAuthentication: false,
};
export const SIGN_UP: PageConfig = {
  name: 'Sign Up',
  path: '/sign-up',
  // Component: React.lazy(() => import('./Login/Login')),
  exact: true,
  requiresAuthentication: false,
};
export const LOGIN_DETAILS: PageConfig = {
  name: 'Login Details',
  path: '/account/login',
  // Component: React.lazy(() => import('./LoginDetails/LoginDetails')),
  exact: true,
  requiresAuthentication: true,
};

export const REQUEST_PASSWORD_RESET: PageConfig = {
  name: 'Reset Password',
  path: '/account/request-password-reset',
  // Component: React.lazy(() => import('./ResetPasswordRequest/ResetPasswordRequest')),
  exact: true,
  requiresAuthentication: false,
};

export const RESET_PASSWORD: PageConfig = {
  name: 'Reset Password',
  path: '/account/reset-password',
  // Component: React.lazy(() => import('./ResetPassword/ResetPassword')),
  exact: true,
  requiresAuthentication: false,
};

export const TERMS_AND_CONDITIONS: PageConfig = {
  name: 'Terms and Conditions',
  description:
    'The terms and conditions of our service, information about us, and service availability.',
  path: '/terms-and-conditions',
  redirectFrom: ['/terms_of_service'],
  // Component: React.lazy(() => import('./TermsAndConditions/TermsAndConditions')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const PRIVACY_NOTICE: PageConfig = {
  name: 'Privacy Notice',
  redirectFrom: ['/privacy_policy', '/privacy-policy'],
  description:
    'Qmee privacy notice which governs the collection, storage and use of personal data collected by us.',
  path: '/privacy-notice',
  // Component: React.lazy(() => import('./Privacy/PrivacyNotice')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const COOKIE_NOTICE: PageConfig = {
  name: 'Cookie Notice',
  description:
    'Qmee cookie notice which explains why we use them and how you can better control their use.',
  path: '/cookie-notice',
  // Component: React.lazy(() => import('./Privacy/CookieNotice')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const PARTNERS: PageConfig = {
  name: 'Partners',
  description: "Here are some of Qmee's partners.",
  path: '/partners',
  requiresAuthentication: false,
  // Component: React.lazy(() => import('./Partners/Partners')),
  exact: true,
  shouldIndexPage: true,
};
export const FAQ: PageConfig = {
  name: 'FAQ',
  description:
    'How do I earn cash with Qmee? Shop, search & take surveys. Is the app free? Yes! What’s the minimum cashout amount? There is none!',
  path: '/faq',
  // Component: React.lazy(() => import('./Faq/Faq')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const SCREENER_SURVEY: PageConfig = {
  name: 'Screener Survey',
  path: '/signup-survey',
  redirectFrom: ['/signup-survey/*'],

  // Component: React.lazy(() => import('./ScreenerSurvey/ScreenerSurvey')),
  exact: true,
  requiresAuthentication: true,
  fullPage: true,
};
export const REFER: PageConfig = {
  name: 'Refer',
  path: '/refer',
  // Component: React.lazy(() => import('./ReferralInvites/ReferralInvites')),
  exact: true,
  requiresAuthentication: true,
};
export const DEMOGRAPHIC_PROFILE: PageConfig = {
  name: 'Update Profile Details',
  path: '/account/profile',
  // Component: React.lazy(() => import('./DemographicProfile/DemographicProfile')),
  exact: true,
  requiresAuthentication: true,
  fullPage: true,
};
export const WHY_QMEE: PageConfig = {
  name: 'Why Qmee?',
  description:
    'Check out these awesome reasons why you should choose Qmee and our amazing community!',
  path: '/why-qmee',
  redirectFrom: ['/why_qmee', '/'],
  // Component: React.lazy(() => import('./WhyQmee/WhyQmee')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const HOW_IT_WORKS: PageConfig = {
  name: 'How it works',
  description:
    'With Qmee you can get rewarded for your opinions, your searches and your purchases. Share, shop & search to earn real cash.',
  path: '/how-it-works',
  redirectFrom: ['/how_it_works'],
  // Component: React.lazy(() => import('./HowItWorks/HowItWorks')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const PRIVACY_PROMISE: PageConfig = {
  name: 'Privacy Promise',
  description: 'Qmee privacy promise to users',
  path: '/privacy-promise',
  redirectFrom: ['/privacy_promise'],
  // Component: React.lazy(() => import('./PrivacyPromise/PrivacyPromise')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const SECURITY: PageConfig = {
  name: 'Security',
  description: 'Report security problems with the Qmee site and apps.',
  path: '/security',
  // Component: React.lazy(() => import('./Security/Security')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const RESPONSEQUALITY: PageConfig = {
  name: 'Response Quality Score',
  path: '/response-quality-score',
  redirectFrom: ['/survey-provider-score'],
  // Component: React.lazy(() => import('./ResponseQuality/ResponseQuality')),
  exact: true,
  requiresAuthentication: true,
};
export const PROTECT_YOUR_ACCOUNT: PageConfig = {
  name: 'Protect your account',
  description: 'How we help protect your account.',
  path: '/protect',
  // Component: React.lazy(() => import('./ProtectYourAccount/ProtectYourAccount')),
  exact: true,
  requiresAuthentication: false,
};
export const BROWSER_APP: PageConfig = {
  name: 'Search',
  path: '/users/install',
  redirectFrom: ['/app'],
  // Component: React.lazy(() => import('./BrowserApp/BrowserApp')),
  exact: true,
  requiresAuthentication: true,
};
export const BROWSER_APP_INSTALLED: PageConfig = {
  name: 'Search',
  path: '/users/app_installed',
  // Component: React.lazy(() => import('./BrowserAppInstalled/BrowserAppInstalled')),
  exact: true,
  requiresAuthentication: true,
};

export const BLOG_POSTS: PageConfig = {
  name: 'Blog',
  path: '/blog',
  // Component: React.lazy(() => import('./BlogPosts/BlogPosts')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};
export const BLOG_POST: PageConfig = {
  name: 'Qmee Blog',
  path: '/blog/:slug',
  // Component: React.lazy(() => import('./BlogPost/BlogPost')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: true,
};

export const REFERPROMO: PageConfig = {
  name: 'Refer a friend promotion',
  description: 'Refer a friend to earn great prizes from Qmee',
  path: '/referral-promotion',
  // Component: React.lazy(() => import('./ReferPromo/ReferPromo')),
  exact: true,
  requiresAuthentication: true,
};

export const PAYPAL_INFO_FORM: PageConfig = {
  name: 'Paypal Information',
  description: 'Complete the information below to be able to cashout to PayPal',
  path: '/cashout-information',
  // Component: React.lazy(() => import('./PaypalInfoForm/PaypalInfoForm')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const SURVEY_TIPS: PageConfig = {
  name: 'Survey Tips',
  path: '/survey-tips',
  description: 'Survey Tips',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTips')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const SURVEY_TIP: PageConfig = {
  name: 'Survey Tip',
  path: '/survey-tips/:slug',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTip')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const SUN_SURVEYS: PageConfig = {
  name: 'The Sun Surveys',
  path: '/sunsurveys',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTip')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const PAID_SURVEYS: PageConfig = {
  name: 'Paid Surveys',
  path: '/paid-surveys',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTip')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const EARN_MONEY: PageConfig = {
  name: 'Earn Money',
  path: '/earn-money',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTip')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const SIDE_HUSTLE: PageConfig = {
  name: 'Sude Hustle',
  path: '/side-hustle',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTip')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const CASH_APP: PageConfig = {
  name: 'Cash App',
  path: '/cash-app',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTip')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const MARKET_RESEARCH: PageConfig = {
  name: 'Market Research',
  path: '/market-research',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTip')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const OPINION: PageConfig = {
  name: 'Opinion',
  path: '/opinion',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTip')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const POLLS: PageConfig = {
  name: 'Polls',
  path: '/polls',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTip')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};

export const QUESTIONNAIRE: PageConfig = {
  name: 'Questionnaire',
  path: '/questionnaire',
  // Component: React.lazy(() => import('./SurveyTips/SurveyTip')),
  exact: true,
  requiresAuthentication: false,
  shouldIndexPage: false,
};
