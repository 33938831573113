/**
 * @generated SignedSource<<b6d5f41ea87a77bcb80e61e0c041a77f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TranslationsInput = {
  appIdentifier?: string | null;
  appVersion?: string | null;
  language: string;
  lastUpdate?: string | null;
  originalEnglish?: ReadonlyArray<string> | null;
};
export type MissingTranslationCollectorMutation$variables = {
  input: TranslationsInput;
};
export type MissingTranslationCollectorMutation$data = {
  readonly uploadMissingTranslations: boolean | null;
};
export type MissingTranslationCollectorMutation = {
  variables: MissingTranslationCollectorMutation$variables;
  response: MissingTranslationCollectorMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "uploadMissingTranslations",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MissingTranslationCollectorMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MissingTranslationCollectorMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "976f7d19422d2f5418c0e961bf18fd20",
    "id": null,
    "metadata": {},
    "name": "MissingTranslationCollectorMutation",
    "operationKind": "mutation",
    "text": "mutation MissingTranslationCollectorMutation(\n  $input: TranslationsInput!\n) {\n  uploadMissingTranslations(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "0ec0b74a023b74c3b2986d23e91426cb";

export default node;
