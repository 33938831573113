import React from 'react';
import { useRouter } from 'next/router';

export default function useRemoveQueryStringParams(paramsToRemove?: string[]) {
  const router = useRouter();
  const routerRef = React.useRef(router);
  routerRef.current = router;
  const removeQueryStringParams = React.useCallback((params: string[]) => {
    const filteredParams = {} as typeof routerRef.current.query;
    let removedParam = false;
    const oldParams = routerRef.current.query;
    for (let key in oldParams) {
      if (params.includes(key)) {
        removedParam = true;
      } else {
        filteredParams[key] = oldParams[key];
      }
    }
    if (removedParam) {
      routerRef.current.push(
        {
          pathname: routerRef.current.pathname,
          query: filteredParams,
          hash: location.hash,
        },
        undefined,
        { shallow: true },
      );
    }
  }, []);
  const removeQueryStringParamsRef = React.useRef(removeQueryStringParams);
  removeQueryStringParamsRef.current = removeQueryStringParams;
  React.useEffect(() => {
    if (router.isReady && paramsToRemove) {
      removeQueryStringParamsRef.current(paramsToRemove);
    }
  }, [paramsToRemove, router.isReady, router.query]);
  return removeQueryStringParams;
}
