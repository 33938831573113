import React from 'react';
import { isSsr } from './useIsSsr';

function getWindowDimensions(): WindowDimensions {
  if (isSsr()) {
    return { width: 0, height: 0 };
  }
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width: width || 0,
    height: height || 0,
  };
}
interface WindowDimensions {
  width: number;
  height: number;
}

export default function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] =
    React.useState<WindowDimensions>(getWindowDimensions);

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    if (isSsr()) {
      return () => {};
    } else {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowDimensions;
}
