import React from 'react';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import { useTrackingAllowed, useMarketingAllowed } from './TrackingProvider';
import usePageTracker from '../../../hooks/usePageTracker';
import { useUserJwtData } from '../AuthenticationProvider/AuthenticationProvider';
import {
  handleAcquisitionEvents,
  handleAcquisitionInitialization,
  handleUserUpdated,
  handleSiteWideInitialization,
} from './trackingUtils';
import useRemoveQueryStringParams from '../../../hooks/useRemoveQueryStringParams';
import { useRouter } from 'next/router';
import Script from 'next/script';
import LANDING_PAGES from 'constants/landingPages';

type Analytics = {
  tracking: boolean;
  marketing: boolean;
};

export interface Props {}

const TRACKED_LANDING_PAGES = ['/', '/sunsurveys'];
for (const page in LANDING_PAGES) {
  TRACKED_LANDING_PAGES.push(`/k/${page}`);
}

const TRACKING_PARAMS = [
  '$3p',
  '$aaid',
  'device_ip',
  'user_agent',
  '~campaign',
  '~campaign_id',
  '~click_id',
  '~cost',
  '~cost_currency',
  '~creative_id',
  '~creative_name',
  '~secondary_publisher',
  '_branch_match_id',
  '_branch_referrer',
  '_filteredParams',
];

const AnalyticsHandler: React.FC<{}> = () => {
  usePageTracker();
  const router = useRouter();
  const trackingCookiePresent = useTrackingAllowed();
  const marketingCookiePresent = useMarketingAllowed();
  const userData = useUserJwtData();

  useRemoveQueryStringParams(TRACKING_PARAMS);

  const { amount, currency, optedIn, messageStatus, studyId } = useQueryStringParams();
  const queryParams = useQueryStringParams();
  const hasTrackingParams = React.useMemo(
    () =>
      TRACKING_PARAMS.some((param: string) =>
        Object.prototype.hasOwnProperty.call(queryParams, param),
      ),
    [queryParams],
  );

  React.useEffect(() => {
    handleUserUpdated(userData?.guid);
  }, [userData?.guid]);

  // Events
  React.useEffect(() => {
    // Page View on Landing Page (Welcome Page)
    if (!router.isReady) {
      return;
    }

    if (router.pathname && TRACKED_LANDING_PAGES.includes(router.pathname)) {
      handleAcquisitionInitialization(
        trackingCookiePresent,
        marketingCookiePresent,
        userData?.guid,
      );
    }
    if (
      router.pathname &&
      router.pathname.startsWith('/surveys') &&
      messageStatus === 'success' &&
      !optedIn &&
      !hasTrackingParams
    ) {
      handleAcquisitionInitialization(
        trackingCookiePresent,
        marketingCookiePresent,
        userData?.guid,
      );
      handleAcquisitionEvents('survey-complete', {
        valueCents: Number(amount),
        valueCurrency: currency,
        userGuid: userData?.guid,
        eventId: studyId,
      });
    } else if (
      router.pathname &&
      router.pathname.startsWith('/surveys') &&
      optedIn &&
      !hasTrackingParams
    ) {
      handleAcquisitionInitialization(
        trackingCookiePresent,
        marketingCookiePresent,
        userData?.guid,
      );
      handleAcquisitionEvents('survey-opt-in', {
        userGuid: userData?.guid,
      });
    }

    handleSiteWideInitialization(trackingCookiePresent);
  }, [
    amount,
    currency,
    optedIn,
    messageStatus,
    userData?.guid,
    studyId,
    hasTrackingParams,
    trackingCookiePresent,
    marketingCookiePresent,
    router.pathname,
    router.isReady,
  ]);
  if (marketingCookiePresent) {
    return (
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5SP4HX8');
        `}
      </Script>
    );
  }
  return null;
};

export default AnalyticsHandler;
