import { Environment } from 'react-relay';
import {
  FetchFunction,
  GraphQLResponse,
  Network,
  RecordSource,
  Observable as RelayObservable,
  Environment as RelayRuntimeEnvironment,
  RequestParameters,
  Store,
  SubscribeFunction,
  Variables,
} from 'relay-runtime';
import { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { Observable, Observer, SubscriptionClient } from 'subscriptions-transport-ws';
import { GATEWAY_SUBSCRIPTION_URL, GATEWAY_URL } from '../apiUrls';

type UnSubscriber = { unsubscribe: () => void };
class AsyncObservableWrapper<T> implements Observable<T> {
  observableFactory: () => Promise<Observable<T>>;

  currentObservable?: Observable<T>;

  constructor(observableFactory: () => Promise<Observable<T>>) {
    this.observableFactory = observableFactory;
  }

  subscribe(observer: Observer<T>) {
    if (this.currentObservable) {
      return this.currentObservable.subscribe(observer);
    } else {
      let shouldSubscribe = true;
      let unSubscriber: UnSubscriber | undefined;
      this.observableFactory().then((observable) => {
        if (shouldSubscribe) {
          this.currentObservable = observable;
          unSubscriber = this.currentObservable.subscribe(observer);
        }
      });
      return {
        unsubscribe: () => {
          shouldSubscribe = false;
          unSubscriber?.unsubscribe();
        },
      };
    }
  }
}
interface EnvironmentOptions {
  getAccessToken: (forceRefresh?: string) => Promise<string | null>;
  onError?: (error: string) => void;
  records?: RecordMap;
  recordSource?: RecordSource;
  locale?: string;
}

export default function createRelayEnvironment({
  getAccessToken,
  onError,
  records,
  recordSource,
  locale,
}: EnvironmentOptions) {
  const source = recordSource || new RecordSource(records);
  const store = new Store(source);
  async function fetchResponse(
    request: RequestParameters,
    variables: Variables,
    accessToken: string | null,
  ) {
    const body = JSON.stringify({
      query: request.text, // GraphQL text from input
      variables,
    });

    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...(locale ? { 'X-QMEE-LOCALE': locale } : {}),
    };
    return fetch(GATEWAY_URL, {
      credentials: 'include',
      method: 'POST',
      body,
      headers,
    });
  }
  const fetchQuery: FetchFunction = async (request: RequestParameters, variables: Variables) => {
    let accessToken = await getAccessToken();
    let response = await fetchResponse(request, variables, accessToken);
    if (response.status === 401 && accessToken) {
      accessToken = await getAccessToken('gateway-401');
      if (!accessToken) {
        throw new Error('Authentication error');
      }
      response = await fetchResponse(request, variables, accessToken);
      if (response.status === 401) {
        throw new Error('Authentication error');
      }
    }
    if (response.status !== 200) {
      const data = await response.text();
      throw new Error(`Server error (${response.status}): ${data}`);
    }
    const data = await response.json();
    if (data?.errors) {
      data.errors.forEach((error: any) => {
        if (onError) {
          onError(error.message);
        }
        // eslint-disable-next-line no-console
        console.warn(`Relay error when fetching ${error.path || request.text}: ${error.message}`);
      });
    }
    return data;
  };

  let subscriptionLink: SubscriptionClient | undefined;
  const subscribeQuery: SubscribeFunction = (
    request: RequestParameters,
    variables: Variables,
  ): RelayObservable<GraphQLResponse> => {
    // We need to delay the websocket connection establishment until we are sure we have an access token and cookie for the connection
    const subscribeObservable = new AsyncObservableWrapper(async () => {
      if (!subscriptionLink) {
        // Wait to have token available. Otherwise the cookie might not have been set before connecting to the gateway
        await getAccessToken();
        subscriptionLink = new SubscriptionClient(GATEWAY_SUBSCRIPTION_URL, {
          reconnect: true,
          lazy: true,
          connectionParams: async () => ({
            token: await getAccessToken(),
          }),
        });
      }
      return subscriptionLink.request({
        query: request.text?.toString(),
        operationName: request.name,
        variables,
      });
    });
    // Important: Convert subscriptions-transport-ws observable type to Relay's
    return RelayObservable.from(subscribeObservable as any);
  };

  const environment = new RelayRuntimeEnvironment({
    network: Network.create(fetchQuery, subscribeQuery),
    store,
  }) as Environment;
  return environment;
}
