import { useEffect } from 'react';
import useQueryStringParams from './useQueryStringParams';
import useLocalStorageState from './useLocalStorageState';

export type UserSourceData = {
  inviteToken: string | null;
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmContent: string | null;
};

export default function useUserSourceData(): UserSourceData {
  const params = useQueryStringParams();
  const [userSourceData, setUserSourceData] = useLocalStorageState<UserSourceData>(
    'user-source-data',
    {
      inviteToken: null,
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      utmContent: null,
    },
  );

  useEffect(() => {
    const newUserSourceData = {
      inviteToken: userSourceData?.inviteToken || params.invite || null,
      utmSource: userSourceData?.utmSource || params.utm_source || null,
      utmMedium: userSourceData?.utmMedium || params.utm_medium || null,
      utmCampaign: userSourceData?.utmCampaign || params.utm_campaign || null,
      utmContent: userSourceData?.utmContent || params.utm_content || null,
    };

    if (params['~campaign'] && !userSourceData?.utmCampaign) {
      newUserSourceData.utmCampaign = params['~campaign'];
      newUserSourceData.utmSource = 'Facebook';
      newUserSourceData.utmMedium = 'paid advertising';
    }

    setUserSourceData(newUserSourceData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setUserSourceData,
    params.invite,
    params.utm_source,
    params.utm_medium,
    params.utm_campaign,
    params.utm_content,
  ]);

  return userSourceData;
}
