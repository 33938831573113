import { Notifier } from '@airbrake/browser';
import React, { ErrorInfo } from 'react';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';

export interface Props {
  children?: React.ReactNode;
  fallback?: React.ReactNode;
}

interface State {
  error?: Error;
}
let airbrake: Notifier;
interface AirbrakeNotification {
  error: Error;
  params?: any;
}
export function notifyAirbrake(err: AirbrakeNotification) {
  if (!airbrake) {
    airbrake = new Notifier({
      projectId: 331941,
      projectKey: '4f6a46417cad940cbeaad285077cba2b',
      environment: `${process.env.NODE_ENV}` || 'development',
    });
    airbrake.addFilter((notice) => {
      const error = notice.errors && notice.errors[0];
      if (
        notice.context.environment === 'development' ||
        notice.context.environment === 'test' ||
        error?.message?.match(
          /.*(Network.*Error|Failed to fetch|internet.*connection|network.*connection|Authentication.*[eE]rror|execute.*removeChild).*/,
        ) ||
        error?.type === 'LoginError'
      ) {
        return null;
      }
      return notice;
    });
  }
  airbrake.notify(err);
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error.message.match(/Loading chunk .* failed/)) {
      window.location.reload();
      return;
    }
    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
    this.setState({ error });
    notifyAirbrake({
      error,
      params: { errorInfo },
    });
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;
    if (error) {
      return fallback === undefined ? <ErrorPage errorMessage={error.message} /> : fallback;
    }
    return children;
  }
}
