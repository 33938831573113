import clsx from 'clsx';
import React from 'react';
import { BLOG_POSTS } from '../../pages/pages';
import A from '../A/A';
import Facebook from './assets/facebook.svg';
import Instagram from './assets/instagram.svg';
import Linkedin from './assets/linkedin.svg';
import Pinterest from './assets/pinterest.svg';
import Rss from './assets/rss-feed-symbol.svg';
import Twitter from './assets/twitter.svg';
import classes from './SocialLinks.module.scss';

export interface Props {
  className?: string;
}

const SocialLinks: React.FC<Props> = ({ className }) => {
  return (
    <nav className={clsx(classes.root, className)}>
      <A to="https://www.facebook.com/qmeeapp">
        <Facebook />
      </A>
      <A to="https://www.instagram.com/qmeeapp">
        <Instagram />
      </A>
      <A to="https://www.linkedin.com/company/qmee">
        <Linkedin />
      </A>
      <A to="https://www.pinterest.com/qmee/">
        <Pinterest />
      </A>
      <A to={BLOG_POSTS.path}>
        <Rss />
      </A>
      <A to="https://twitter.com/qmee">
        <Twitter />
      </A>
    </nav>
  );
};
export default SocialLinks;
