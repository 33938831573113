/**
 * @generated SignedSource<<3b3797ab31f7e1a58b0beb216341f73f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminPanelQuery$variables = {};
export type AdminPanelQuery$data = {
  readonly pingPongCounter: {
    readonly " $fragmentSpreads": FragmentRefs<"PingPongContainer_pingPong">;
  } | null;
};
export type AdminPanelQuery = {
  variables: AdminPanelQuery$variables;
  response: AdminPanelQuery$data;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminPanelQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PingPongCounter",
        "kind": "LinkedField",
        "name": "pingPongCounter",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PingPongContainer_pingPong"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminPanelQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PingPongCounter",
        "kind": "LinkedField",
        "name": "pingPongCounter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "counter",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "62c07c2fd636405f685e3931dbd0b3aa",
    "id": null,
    "metadata": {},
    "name": "AdminPanelQuery",
    "operationKind": "query",
    "text": "query AdminPanelQuery {\n  pingPongCounter {\n    ...PingPongContainer_pingPong\n    id\n  }\n}\n\nfragment PingPongContainer_pingPong on PingPongCounter {\n  id\n  counter\n}\n"
  }
};

(node as any).hash = "b41f8baf7a3ecdb21ddbcf0bf8131892";

export default node;
