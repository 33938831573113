export type LocaleValue = 'en' | 'de' | 'fr';

export function getLanguageFromLocale(locale: string): LocaleValue {
  return (locale.split('-')[0] || 'en') as LocaleValue;
}
export function getCountryFromLocale(locale: string): string {
  return (locale.split('-')[1] || 'us').toLowerCase();
}
export function getCurrencyFromLocale(locale: string) {
  const country = getCountryFromLocale(locale);
  switch (country) {
    case 'gb':
      return 'GBP';
    case 'ca':
      return 'CAD';
    case 'au':
      return 'AUD';
    case 'de':
    case 'fr':
      return 'EUR';
    case 'us':
    default:
      return 'USD';
  }
}

export function formatCurrency(
  locale: string,
  amount: number,
  currency: string,
  ignoreCents: boolean,
): string {
  if (amount < 100) {
    return `${Math.round(amount)}${currency === 'GBP' ? 'p' : '¢'}`;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: ignoreCents ? 0 : undefined,
  }).format(amount / 100);
}

export const URL_LOCALE_DATE_LOCALE_MAP: { [key: string]: string } = {
  us: 'en-us',
  gb: 'en-gb',
  ca: 'en-ca',
  au: 'en-au',
  de: 'de',
  fr: 'fr',
};
