import React from 'react';
import Cookies from 'js-cookie';
import {
  AuthenticationContextValue,
  AuthenticationTokenState,
  LoginWithPassword,
  LoginWithToken,
  TOKENS_STORAGE_KEY,
  SignUp,
  UpdateTokens,
  UserData,
} from './Types';
import {
  submitLogin,
  submitLoginToken,
  logOut,
  fetchNewTokens,
  isTokenExpired,
  submitSignUp,
  getUserDataFromToken,
} from './authApiUtils';
import useUserSourceData from '../../../hooks/useUserSourceData';
import useConfirmationToken from './useConfirmationToken';
import useLocalStorageState from '../../../hooks/useLocalStorageState';
import useIsSsr, { isSsr } from '../../../hooks/useIsSsr';

export const DEFAULT_AUTHENTICATION_CONTEXT = {
  signUp: () => Promise.resolve(null),
  login: () => Promise.resolve(null),
  loginWithToken: () => Promise.resolve(null),
  logOut: () => {},
  updateTokens: () => Promise.resolve(),
  getAccessToken: () => Promise.resolve(null),
  refreshToken: null,
  isLoggedIn: false,
  userData: null,
};
export const AuthenticationContext = React.createContext<AuthenticationContextValue>(
  DEFAULT_AUTHENTICATION_CONTEXT,
);

export interface Props {
  children: React.ReactNode;
}
const AuthenticationProvider: React.FC<Props> = ({ children }) => {
  const [tokens, setTokens] = useLocalStorageState<AuthenticationTokenState>(
    TOKENS_STORAGE_KEY,
    null,
  );
  const tokensRef = React.useRef(tokens);
  tokensRef.current = tokens; // tokens can change if another tab is updating them. We need to make sure to keep the ref up to date

  const invalidUserTimeRef = React.useRef(false);
  const fetchFreshTokens = React.useRef<Promise<AuthenticationTokenState> | null>(null);
  const userSourceData = useUserSourceData();

  const handleTokensUpdated = React.useCallback(
    (updatedTokens: AuthenticationTokenState) => {
      tokensRef.current = updatedTokens;
      if (updatedTokens) {
        if (isTokenExpired(updatedTokens.accessToken)) {
          // Prevent us from being DOSed if a users time is incorrect
          invalidUserTimeRef.current = true;
        }
        document.documentElement.setAttribute('data-logged-in', 'true');
      } else {
        localStorage.removeItem(TOKENS_STORAGE_KEY);
        document.documentElement.setAttribute('data-logged-in', 'false');
      }
      // We need to make sure to update the reference before setting the tokens and starting to render the logged in part of the website/fetching data
      setTokens(updatedTokens);
    },
    [setTokens],
  );
  useConfirmationToken(handleTokensUpdated);

  const handleSignUp = React.useCallback(
    (email: string, password: string, marketing: boolean) =>
      submitSignUp(email, password, marketing, userSourceData).then((t) => {
        handleTokensUpdated(t);
        return getUserDataFromToken(t?.accessToken);
      }),
    [handleTokensUpdated, userSourceData],
  );

  const handleLogin = React.useCallback(
    (email: string, password: string) =>
      submitLogin(email, password).then((t) => {
        handleTokensUpdated(t);
        return getUserDataFromToken(t?.accessToken);
      }),
    [handleTokensUpdated],
  );

  const handleTokenLogin = React.useCallback(
    (token: string) =>
      submitLoginToken(token).then((t) => {
        handleTokensUpdated(t);
        return getUserDataFromToken(t?.accessToken);
      }),
    [handleTokensUpdated],
  );

  const handleLogOut = React.useCallback(
    (trigger: string | null = null) => {
      logOut(tokensRef.current?.refreshToken, trigger || 'unknown');
      handleTokensUpdated(null);
    },
    [handleTokensUpdated],
  );

  // If the user is logged out but has a logged_in cookie something has gone wrong and we should clear the cookies by doing a logout
  React.useEffect(() => {
    if (!tokensRef.current && Cookies.get('logged_in')) {
      // If the logged in cookie still exists without the tokens in the local storage we want to clean up all cookies by logging the user out
      Cookies.remove('logged_in');
      handleLogOut('invalid-logged_in-cookie');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // It is important that this callback reference is never updated otherwise relay will create a new ENV and refetch all data
  const getAccessToken = React.useCallback(
    async (forceReloadReason: string | null = null) => {
      if (!tokensRef.current?.refreshToken) {
        return null;
      }

      if (
        forceReloadReason ||
        (!invalidUserTimeRef.current && isTokenExpired(tokensRef.current.accessToken)) ||
        fetchFreshTokens.current
      ) {
        // If we are already fetching a new token we want to wait for the existing request instead
        fetchFreshTokens.current =
          fetchFreshTokens.current ||
          fetchNewTokens(tokensRef.current?.refreshToken, forceReloadReason || 'expired').catch(
            (err) => {
              // eslint-disable-next-line no-console
              console.error('Token refetch error', err);
              fetchFreshTokens.current = null;
              if (err.status === 401) {
                handleLogOut('auth-401');
              }
              throw err;
            },
          );
        const freshTokens = await fetchFreshTokens.current;
        fetchFreshTokens.current = null;
        handleTokensUpdated(freshTokens);
        return freshTokens?.accessToken || null;
      }
      return tokensRef.current?.accessToken || null;
    },
    [tokensRef, handleTokensUpdated, handleLogOut, fetchFreshTokens],
  );

  React.useEffect(() => {
    if (tokens?.accessToken) {
      // Force the accessToken being refreshed in case it has expired
      getAccessToken();
    }
  }, [tokens, getAccessToken]);

  const contextValue = React.useMemo<AuthenticationContextValue>(
    () => ({
      signUp: handleSignUp,
      getAccessToken,
      refreshToken: tokens?.refreshToken || null,
      login: handleLogin,
      loginWithToken: handleTokenLogin,
      updateTokens: handleTokensUpdated,
      isLoading: handleLogin,
      isLoggedIn: Boolean(tokens?.accessToken),
      logOut: handleLogOut,
      userData: getUserDataFromToken(tokens?.accessToken),
    }),
    [
      tokens,
      handleSignUp,
      handleLogin,
      handleTokenLogin,
      handleLogOut,
      getAccessToken,
      handleTokensUpdated,
    ],
  );
  return (
    <AuthenticationContext.Provider value={contextValue}>{children}</AuthenticationContext.Provider>
  );
};

// hooks and provider exports
export default AuthenticationProvider;

export function useSignUp(): SignUp {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.signUp;
}
export function useAccessToken(): (reason?: string) => Promise<string | null> {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.getAccessToken;
}
export function useRefreshToken(): string | null {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.refreshToken;
}
export function useIsLoggedIn(): boolean {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  const isSsr = useIsSsr();
  return context.isLoggedIn && !isSsr;
}
export function useLogin(): LoginWithPassword {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.login;
}
export function useLoginWithToken(): LoginWithToken {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.loginWithToken;
}
export function useUpdateTokens(): UpdateTokens {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.updateTokens;
}
type LogOut = (reason: string) => void;
export function useLogOut(): LogOut {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.logOut;
}
export function useUserJwtData(): UserData | null {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.userData;
}
