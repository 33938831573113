import classes from './PageContentWrapper.module.scss';
import clsx from 'clsx';

export interface Props {
  children: React.ReactNode;
  className?: string;
  id?: string;
  topBottomPadding?: boolean;
}

const PageContentWrapper: React.FC<Props> = ({ children, className, id, topBottomPadding }) => {
  return (
    <div
      className={clsx(classes.root, className, topBottomPadding && classes.topBottomPadding)}
      id={id}
    >
      {children}
    </div>
  );
};

export default PageContentWrapper;
