import {
  initializeHotjar,
  initializeFacebook,
  initializeGtag,
  initializeBranch,
  initializeTwitter,
  initializeGTM,
  bingUET,
  initializeClarity,
} from './trackingScripts';

type EventNames = 'survey-complete' | 'survey-opt-in' | 'sign-up';
interface EventOptions {
  valueCents?: number;
  valueCurrency?: string;
  userGuid?: string;
  eventId?: string;
}
declare global {
  interface Window {
    branch?: {
      logEvent: (eventName: string, data: any) => void;
      setIdentity: (guid: string) => void;
    };
    fbq?: (type: 'trackCustom' | 'track', eventName: string, data?: any, options?: any) => void;
    gtag?: (type: 'event' | 'config', eventName: string, data?: any) => void;
    twq?: (type: 'track', eventName: string, data?: any) => void;
    // uetq has different structure to the other events in this list
    uetq?: any;
  }
}

function eventValue(options: EventOptions): { value: number; currency: string } {
  const currency = options.valueCurrency?.toUpperCase() || 'USD';
  if (!options.valueCents || options.valueCents < 0) {
    return { value: 0, currency };
  }
  if (options.valueCents > 300) {
    return { value: 3.0, currency };
  }
  return { value: options.valueCents / 100.0, currency };
}

// Adwords needs specific codes to work
const ADWORD_EVENT_MAP = {
  'sign-up': 'AW-972103332/n0qnCIuK4MgBEKS9xM8D',
  'survey-opt-in': 'AW-972103332/DW1dCNfgxd8BEKS9xM8D',
  'survey-complete': 'AW-972103332/6dUuCLiXscICEKS9xM8D',
};
export function handleAdwordsEvent(eventName: EventNames, options: EventOptions) {
  const event = ADWORD_EVENT_MAP[eventName];
  if (window.gtag && event) {
    window.gtag('event', 'conversion', {
      send_to: event,
      ...eventValue(options),
    });
  }
}

// Branch needs PURCHASE (commerce) event to record revenue
const BRANCH_EVENT_MAP = {
  'sign-up': 'SIGN_UP_WEB_JOURNEY',
  'survey-opt-in': 'SURVEY_OPT_IN',
  'survey-complete': 'PURCHASE',
};
export function handleBranchEvent(eventName: EventNames, options: EventOptions) {
  const event = BRANCH_EVENT_MAP[eventName];
  if (window.branch && eventName) {
    const { value, currency } = eventValue(options);
    const data = {
      currency,
      revenue: value,
    };
    window.branch.logEvent(event, data);
  }
}

export function handleUserUpdated(userGuid: string | undefined) {
  if (userGuid) {
    window.branch?.setIdentity(userGuid);
  }
}
export function handlePageView() {
  if (window.fbq) {
    window.fbq('track', 'PageView');
  }
  if (window.twq) {
    window.twq('track', 'PageView');
  }
}

export function handleGoogleEvent(eventName: EventNames, options: EventOptions) {
  if (window.gtag) {
    window.gtag('event', eventName, {
      event_category: 'web-event',
      event_label: 'complete',
      ...eventValue(options),
    });
  }
}

// we are limited to 8 events. to avoid exceeding this in the future, default to null
const FACEBOOK_EVENT_MAP = {
  'sign-up': 'Signup',
  'survey-opt-in': 'Add to cart',
  'survey-complete': 'Purchase',
};
export function handleFacebookEvent(eventName: EventNames, options: EventOptions) {
  const event = FACEBOOK_EVENT_MAP[eventName];

  if (window.fbq && event) {
    // Tracking data:
    const extras = {
      eventID: options.eventId || options.userGuid || undefined,
      event_id: options.eventId || options.userGuid || undefined,
      externalID: `${event}-${options.userGuid}` || undefined,
      external_id: `${event}-${options.userGuid}` || undefined,
    };
    window.fbq(
      'track',
      event,
      {
        ...eventValue(options),
        // The API documentation is not very clear where it wants that data so we just send it as both
        ...extras,
      },
      extras,
    );
  }
}

const TWITTER_EVENT_MAP = {
  'sign-up': 'CompleteRegistration',
  'survey-opt-in': 'AddToCart',
  'survey-complete': 'Purchase',
};

export function handleTwitterEvent(eventName: EventNames, options: EventOptions) {
  const event = TWITTER_EVENT_MAP[eventName];
  if (window.twq) {
    window.twq('track', event, {
      num_items: '1',
      ...eventValue(options),
    });
  }
}

const BING_EVENT_MAP = {
  'sign-up': 'signup',
  'survey-opt-in': 'addtocart',
  'survey-complete': 'purchase',
};

export function handleBingEvent(eventName: EventNames, options: EventOptions) {
  const event = BING_EVENT_MAP[eventName];
  const revenue = eventValue(options);
  if (window.uetq) {
    window.uetq.push('event', event, {
      event_category: 'web-event',
      event_label: 'complete',
      event_value: revenue.value,
      currency: revenue.currency,
    });
  }
}

// Clean Up
export function clearGtag() {
  window.gtag = undefined;
}

export function handleAcquisitionInitialization(
  trackingCookiePresent: Boolean,
  marketingCookiePresent: Boolean,
  userGuid: string | undefined,
) {
  // initialize tracking scripts at the point that they are required
  if (trackingCookiePresent) {
    initializeGtag();
    initializeHotjar();
  }
  if (marketingCookiePresent) {
    initializeBranch(userGuid);
    initializeFacebook();
    initializeTwitter();
    initializeGTM();
    handleUserUpdated(userGuid);
  }
  handlePageView();
  if (trackingCookiePresent && marketingCookiePresent) {
    // bingUET is somewhat a cross between a remarketing tag and google analytics
    bingUET();
  }
}

export function handleSiteWideInitialization(trackingCookiePresent: Boolean) {
  if (trackingCookiePresent) {
    initializeClarity();
  }
}

export function handleAcquisitionEvents(eventName: EventNames, options: EventOptions) {
  handleGoogleEvent(eventName, options);
  handleAdwordsEvent(eventName, options);
  handleBranchEvent(eventName, options);
  handleFacebookEvent(eventName, options);
  handleTwitterEvent(eventName, options);
  handleBingEvent(eventName, options);
}
