import { useRouter } from 'next/router';
import React from 'react';
import { formatCurrency, getCurrencyFromLocale } from '../utils/locale';

export default function useFormatCurrency() {
  const router = useRouter();
  const locale = router.locale || 'en-us';
  const defaultCurrency = getCurrencyFromLocale(locale);
  return React.useCallback(
    (amount: number, currency = defaultCurrency, ignoreCents = false) => {
      return formatCurrency(locale, amount, currency || defaultCurrency, ignoreCents);
    },
    [defaultCurrency, locale],
  );
}
