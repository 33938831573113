import { GraphQLTaggedNode } from 'react-relay';
import {
  useRefetchableFragment,
  useSubscribeToInvalidationState,
  useSubscription,
  RefetchFnDynamic,
} from 'react-relay/hooks';
import {
  OperationType,
  RecordSourceSelectorProxy,
  FragmentReference,
  FragmentType,
} from 'relay-runtime';
import React from 'react';
import { graphql } from 'react-relay';
import {
  useLiveFragmentSubscription,
  useLiveFragmentSubscription$data,
} from './__generated__/useLiveFragmentSubscription.graphql';

/**
 *
 * @param fragmentSpec grapqhl fragment specification with fragment tagged with: `@refetchable(queryName: "[ComponentName]RefetchQuery")`
 */

export type FragmentKeyType<TData = unknown> = Readonly<{
  ' $data'?: TData;
  ' $fragmentSpreads': FragmentType;
}>;

export type KeyTypeData<
  TKey extends FragmentKeyType<TData>,
  TData = unknown,
> = Required<TKey>[' $data'];

function useLiveFragment<T extends OperationType>(
  fragmentSpec: GraphQLTaggedNode,
  factionRef: any,
) {
  const [fragment, refetch] = useRefetchableFragment<T, any>(fragmentSpec, factionRef);
  useSubscribeToInvalidationState([fragment.id], () => {
    refetch({}, { fetchPolicy: 'store-or-network' });
  });
  return fragment;
}
export default useLiveFragment;

export function useRefetchableLiveFragment<T extends OperationType, TKey extends FragmentKeyType>(
  fragmentSpec: GraphQLTaggedNode,
  factionRef: TKey,
): [KeyTypeData<TKey>, RefetchFnDynamic<T, any>] {
  const [fragment, refetch] = useRefetchableFragment<T, any>(fragmentSpec, factionRef);

  useSubscribeToInvalidationState([fragment.id], () => {
    refetch({}, { fetchPolicy: 'store-and-network' });
  });
  return [fragment, refetch];
}

const subscriptionSpec = graphql`
  subscription useLiveFragmentSubscription {
    dataInvalidated {
      nodeId
      queryName
    }
  }
`;
export function useNodeSubscription() {
  const subscriptionConfig = React.useMemo(
    () => ({
      subscription: subscriptionSpec,
      variables: {},
      updater: (
        store: RecordSourceSelectorProxy<unknown>,
        response: useLiveFragmentSubscription$data,
      ) => {
        if (response.dataInvalidated.nodeId) {
          const field = store.get(response.dataInvalidated.nodeId);
          if (field) {
            field.invalidateRecord();
          }
        }
      },
    }),
    [],
  );
  useSubscription<useLiveFragmentSubscription>(subscriptionConfig);
}
export function RelayNodeSubscriber() {
  useNodeSubscription();
  return null;
}
