import React, { useState, createContext, useContext } from 'react';
import dynamic from 'next/dynamic';

const DynamicSurveyFeedbackModal = dynamic(
  () => import('components/organisms/SurveyFeedbackModal/SurveyFeedbackModal'),
);

const DynamicReviewPromptModal = dynamic(
  () => import('components/organisms/ReviewPromptModal/ReviewPromptModal'),
);

export const MODAL_TYPES = {
  SURVEY_FEEDBACK_MODAL: 'SURVEY_FEEDBACK_MODAL',
  REVIEW_PROMPT_MODAL: 'REVIEW_PROMPT_MODAL',
};

export interface Props {
  hasModal?: boolean;
  children?: React.ReactNode;
}

type ModalContextType = {
  showModal: (modalType: string, modalProps?: any) => void;
  hideModal: () => void;
  modalType: string;
  modalProps?: any;
};

const initialState: ModalContextType = {
  showModal: () => {},
  hideModal: () => {},
  modalType: '',
  modalProps: {},
};

const ModalContext = createContext(initialState);
export function useModalContext() {
  return useContext(ModalContext);
}

const ModalProvider: React.FC<Props> = ({ children, hasModal = false }) => {
  const [store, setStore] = useState(initialState);
  const { modalType, modalProps } = store || {};

  const showModal = (mdtype: string, mdprops: any = {}) => {
    setStore({
      ...store,
      modalType: mdtype,
      modalProps: mdprops,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: '',
      modalProps: {},
    });
  };

  return (
    <ModalContext.Provider value={{ ...store, showModal, hideModal }}>
      {modalType === MODAL_TYPES.SURVEY_FEEDBACK_MODAL && (
        <DynamicSurveyFeedbackModal id="survey-feedback-modal" {...modalProps} />
      )}
      {modalType === MODAL_TYPES.REVIEW_PROMPT_MODAL && (
        <DynamicReviewPromptModal id="review-prompt-modal" {...modalProps} />
      )}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
