import React from 'react';
import Link from 'next/link';

export interface AProps {
  className?: string;
  to: string;
  rel?: string;
}
export type Props = AProps & React.HTMLProps<HTMLAnchorElement>;

const A = (
  { className, to, rel, children, onClick, ...rest }: Props,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) => {
  const doNotIncludeRel = to.startsWith('mailto:') || to.startsWith('#');
  const builtRel = `${rel ? `${rel} ` : ''}noopener noreferrer`;

  // this makes sure stuff like mailto: and http or https still works
  // basically, if the route starts with no domain then it'll always be
  // a component/react-router link.
  if (!to.startsWith('/')) {
    return (
      <a
        ref={ref}
        className={className}
        href={to}
        onClick={onClick}
        {...rest}
        rel={doNotIncludeRel ? undefined : builtRel}
      >
        {children}
      </a>
    );
  }
  return (
    <Link className={className} {...rest} href={to} rel={rel} ref={ref}>
      {/* react-router seems to have broken type definitions and does not accept a ReactNode as children */}
      <a onClick={onClick} className={className}>
        {children}
      </a>
    </Link>
  );
};

export default React.forwardRef(A);
