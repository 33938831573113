import React from 'react';
import { useAlert } from 'react-alert';
import { graphql } from 'react-relay';
import classes from './AdminPanel.module.scss';
import { useLazyLoadQuery } from 'react-relay';
import useLocalStorageState from '../../../hooks/useLocalStorageState';
import Cross from '../../assets/cross.svg';

import Button from '../../atoms/Button/Button';
import { AdminPanelQuery } from './__generated__/AdminPanelQuery.graphql';
import PingPongContainer from './PingPongContainer';

export interface Props {
  onClose: () => void;
}

const query = graphql`
  query AdminPanelQuery {
    pingPongCounter {
      ...PingPongContainer_pingPong
    }
  }
`;

export const AB_TESTS_STORAGE_KEY = 'oldABTestsToggle';

const AdminPanel: React.FC<Props> = ({ onClose }) => {
  const ref = React.createRef<HTMLDivElement>();

  const data = useLazyLoadQuery<AdminPanelQuery>(query, {
    fetchPolicy: 'store-or-network',
  });
  const alert = useAlert();
  const [abTestToggle, setAbTestToggle] = useLocalStorageState<boolean>(
    AB_TESTS_STORAGE_KEY,
    false,
    { storeInitialValue: true },
  );

  const [adminSurveyToggle, setAdminSurveyToggle] = useLocalStorageState<boolean>(
    'adminSurveys',
    false,
    { storeInitialValue: false },
  );

  return (
    <div ref={ref} className={classes.root}>
      <button onClick={onClose} className={classes.closeBtn}>
        <Cross />
      </button>
      <div className={classes.layout}>
        <label htmlFor="toggleabtestfeatures">
          <input
            id="toggleabtestfeatures"
            type="checkbox"
            checked={abTestToggle}
            onChange={(e) => setAbTestToggle(e.target.checked)}
          />
          AB tests
        </label>
        <div className={classes.layout}>
          <label htmlFor="toggleadminsurveyinfo">
            <input
              id="toggleadminsurveyinfo"
              type="checkbox"
              checked={adminSurveyToggle}
              onChange={(e) => setAdminSurveyToggle(e.target.checked)}
            />
            Admin Survey Info
          </label>
        </div>
        {data.pingPongCounter && <PingPongContainer pingPongRef={data.pingPongCounter} />}
        <Button
          onClick={() =>
            alert.show('Lorem ipsum', {
              type: 'success',
              timeout: 5000,
            })
          }
        >
          Trigger alert
        </Button>
      </div>
    </div>
  );
};

export default AdminPanel;
