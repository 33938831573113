import { useDictionary } from 'components/templates/LocaisationProvider/DictionaryProvider';
import React from 'react';

export interface TranslationOptions {
  plural?: string;
  vars?: (string | number)[];
}
export type TranslationFunction = (text: string, options?: TranslationOptions) => string;

const fillAllVariables = (textRaw: string, varsIn: (string | number)[]) => {
  let textFilled = textRaw;
  varsIn.forEach((element, index) => {
    textFilled = textFilled.replace(
      new RegExp(`%${index + 1}s;`, 'g'),
      typeof element === 'number' ? element.toString() : element,
    );
  });
  return textFilled;
};

const isPlural = (varCheck: string | number) => {
  const numCheck = typeof varCheck === 'number' ? varCheck : parseInt(varCheck, 10);
  return numCheck > 1;
};

export default function useTranslate(): TranslationFunction {
  const dictionary = useDictionary();

  return React.useCallback(
    (text: string, options?: TranslationOptions) => {
      if (!options?.vars) {
        return dictionary(text) || text;
      } else if (options?.plural && isPlural(options.vars[0])) {
        return fillAllVariables(dictionary(options.plural) || options.plural, options?.vars);
      } else {
        return fillAllVariables(dictionary(text) || text, options?.vars);
      }
    },
    [dictionary],
  );
}
