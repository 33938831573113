/**
 * @generated SignedSource<<8486527b8d03210b9c0aefbc1d9d102f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PingPongContainerMutation$variables = {};
export type PingPongContainerMutation$data = {
  readonly pingPongTriggered: {
    readonly " $fragmentSpreads": FragmentRefs<"PingPongContainer_pingPong">;
  } | null;
};
export type PingPongContainerMutation = {
  variables: PingPongContainerMutation$variables;
  response: PingPongContainerMutation$data;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PingPongContainerMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PingPongCounter",
        "kind": "LinkedField",
        "name": "pingPongTriggered",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PingPongContainer_pingPong"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PingPongContainerMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PingPongCounter",
        "kind": "LinkedField",
        "name": "pingPongTriggered",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "counter",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aba41e76948c30095e8fc91b07f50b3b",
    "id": null,
    "metadata": {},
    "name": "PingPongContainerMutation",
    "operationKind": "mutation",
    "text": "mutation PingPongContainerMutation {\n  pingPongTriggered {\n    ...PingPongContainer_pingPong\n    id\n  }\n}\n\nfragment PingPongContainer_pingPong on PingPongCounter {\n  id\n  counter\n}\n"
  }
};

(node as any).hash = "e167e4f568176c31f901aa3980ab4968";

export default node;
