import React from 'react';
import EmbarassedOinq from '../../assets/oinq/embarassedOinq.svg';
import BrokenBackground from '../../assets/broken-background.svg';
import A from '../../atoms/A/A';
import * as pages from '../pages';
import classes from './ErrorPage.module.scss';

export interface Props {
  errorMessage?: string;
  errorDescription?: string;
  children?: React.ReactNode;
}

const ErrorPage: React.FC<Props> = ({ errorMessage, errorDescription, children }) => {
  return (
    <article className={classes.root}>
      <div>
        <h2>
          Uh Oh!
          <br />
          <br />
          {errorDescription || 'Something seems to have gone wrong'}
        </h2>
        <p>
          Listen, we know a lot of things but we don&apos;t know why this has happened but we
          promise we&apos;ll find out!
          <br />
          {errorMessage && (
            <>
              <br />
              <code>{errorMessage}</code>
            </>
          )}
        </p>
        <hr />
        <p>In the meantime you might be able to find what you are looking for in these places...</p>
        <nav>
          {children}
          <A to={pages.DASHBOARD.path}>Qmee Home</A>
          <A to={pages.FAQ.path}>FAQs</A>
          <A to={pages.CONTACT_US.path}>Contact Qmee</A>
        </nav>
      </div>
      <div>
        <BrokenBackground className={classes.brokenBackground} />
        <EmbarassedOinq />
      </div>
    </article>
  );
};
export default ErrorPage;
