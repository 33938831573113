import { AB_TESTS_STORAGE_KEY } from '../components/organisms/AdminPanel/AdminPanel';
import { useUserJwtData } from '../components/templates/AuthenticationProvider/AuthenticationProvider';
import useLocalStorageState from './useLocalStorageState';

export type TestGroup = {
  name: string;
};

export default function useInTestGroup(groupName: string): boolean {
  const [abTestsToggled] = useLocalStorageState(AB_TESTS_STORAGE_KEY, false);
  const userData = useUserJwtData();
  return (
    (userData?.roles?.includes('admin') && abTestsToggled) ||
    userData?.roles?.includes(groupName) ||
    false
  );
}
