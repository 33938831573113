/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import LoggedOutFooter from './LoggedOutFooter';

export interface Props {}

const Footer: React.FC<Props> = () => {
  return <LoggedOutFooter />;
};

const MemoizedFooter = React.memo(Footer);

export default MemoizedFooter;
