import React from 'react';
import { graphql } from 'react-relay';
import { useMutation } from 'react-relay';
import {
  PingPongContainer_pingPong$data,
  PingPongContainer_pingPong$key,
} from './__generated__/PingPongContainer_pingPong.graphql';
import useLiveFragment from '../../../relay/useLiveFragment';
import Button from '../../atoms/Button/Button';

export interface FragmentComponentProps {
  pingPongRef: PingPongContainer_pingPong$key;
}

const adminPanelMutation = graphql`
  mutation PingPongContainerMutation {
    pingPongTriggered {
      ...PingPongContainer_pingPong
    }
  }
`;

const fragmentSpec = graphql`
  fragment PingPongContainer_pingPong on PingPongCounter
  @refetchable(queryName: "AdminPanelRefetchQuery") {
    id
    counter
  }
`;

const PingPongContainer: React.FC<FragmentComponentProps> = ({ pingPongRef }) => {
  const [triggerPingPong, isTriggeringPingPong] = useMutation<any>(adminPanelMutation);
  const pingPong: PingPongContainer_pingPong$data = useLiveFragment(fragmentSpec, pingPongRef);
  function handleTestWebsocket() {
    triggerPingPong({ variables: {} });
  }

  return (
    <Button onClick={handleTestWebsocket} disabled={isTriggeringPingPong}>
      Test Websockets: {pingPong?.counter}
    </Button>
  );
};

export default PingPongContainer;
