import React, { ReactNode } from 'react';
import clsx from 'clsx';
import classes from './Button.module.scss';

export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'text';
export type ButtonColor = 'primary' | 'secondary' | 'accent';

interface ButtonStyleProps {
  buttonSize?: 'small' | 'normal' | 'large';
  color?: ButtonColor;
  buttonType?: ButtonType;
  icon?: ReactNode;
  iconPosition?: 'before' | 'after';
}

type Without<T, K> = Pick<T, Exclude<keyof T, K>>;
export type Props<T extends React.ElementType = 'button'> = {
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  component?: T;
  prefetch?: boolean;
  ref?: React.ForwardedRef<T>;
} & ButtonStyleProps &
  Without<React.ComponentPropsWithoutRef<T>, ButtonStyleProps>;

function Button<T extends React.ElementType = 'button'>({
  className,
  disabled,
  children,
  component,
  buttonSize = 'normal',
  color = 'primary',
  buttonType = 'secondary',
  icon,
  iconPosition,
  ref,
  ...rest
}: Props<T>) {
  const Root = (component || 'button') as React.ElementType;

  const buttonIcon = (
    <span
      className={clsx(classes.buttonIcon, iconPosition === 'after' && classes.iconAfter)}
      aria-hidden="true"
    >
      {icon}
    </span>
  );

  return (
    <Root
      type="button"
      className={clsx(
        classes.root,
        classes[`type-${buttonType}`],
        classes[`color-${color}`],
        classes[`size-${buttonSize}`],
        disabled ? classes.disabled : '',
        className,
      )}
      ref={ref}
      disabled={disabled}
      {...rest}
    >
      {icon && iconPosition === 'before' && buttonIcon}
      {children}
      {icon && iconPosition === 'after' && buttonIcon}
    </Root>
  );
}

export default Button;
