// From universal average of 183 wpm (4 words ps), but weighted down
// 50% to accomidate all potential users with some padding on top.
// Smaller the number, the greater the duration.
const WORDS_PER_SECOND = 1.75;
export const DEFAULT_TIMEOUT = 5000;

export function alertMessageTimeout(message: string = '') {
  if (!message) {
    return DEFAULT_TIMEOUT;
  }
  const messageWords = message.split(' ').length;
  if (messageWords < 8) {
    return DEFAULT_TIMEOUT;
  }

  return (messageWords / WORDS_PER_SECOND) * 1000;
}
