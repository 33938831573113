/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import classes from './Header.module.scss';
import { useRouter } from 'next/router';
import Logo from '../../assets/Logo.svg';
import { useIsLoggedIn } from '../../templates/AuthenticationProvider/AuthenticationProvider';
import { DASHBOARD, WELCOME, SUN_SURVEYS } from '../../pages/pages';
import LoggedOutHeader from './LoggedOutHeader';
import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';
import NavigationBar from 'components/organisms/NavigationBar/NavigationBar';
import PageContentWrapper from 'components/templates/Page/PageContentWrapper';
import A from '../../atoms/A/A';
import useIsSsr from 'hooks/useIsSsr';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import useScrollToElement from 'hooks/useScrollToElement';
import Txt from 'components/atoms/Txt/Txt';
import useFormatCurrency from 'hooks/useFormatCurrency';

/* The 'loading' property is used here instead of React.Suspense due to Storybook not
yet handling Next.js Dynamic imports with suspense. This may change in Storybook V7:
https://github.com/storybookjs/storybook/issues/17831 */
const DynamicLoggedInHeader = dynamic(() => import('./LoggedInHeader'), {
  loading: () => <LoadingIndicator />,
});

// The logic for displaying third party brands will need improving if more brands are added
const DynamicBrandHeaderLogo = dynamic(() => import('./brands/TheSun/TheSunHeaderLogo'));

export interface Props {}
export const HEADER_HEIGHT = '140px';

const Header: React.FC<Props> = () => {
  const isLoggedIn = useIsLoggedIn();
  const isSsr = useIsSsr();
  const scrollToElement = useScrollToElement();
  const { pathname, locale } = useRouter();
  const isExternalBrand = pathname === '/sunsurveys';
  const formatCurrency = useFormatCurrency();

  const onHeaderTagClick = () => {
    scrollToElement('landingPageScrollToRow');
  };

  return (
    <header className={clsx(classes.root, isExternalBrand && classes.isExternalBrand)}>
      <div className={classes.upperHeaderContainer}>
        <div className={classes.upperHeader}>
          {/* There is some logic in this link to redirect to an "external" brand path.
          Currently this only exists for The Sun landing page, as it is the only external brand we're testing so far */}
          <A
            to={
              isLoggedIn && !isSsr
                ? DASHBOARD.path
                : isExternalBrand
                ? SUN_SURVEYS.path
                : WELCOME.path
            }
          >
            <Logo className={classes.logo} />
          </A>

          {isExternalBrand && <DynamicBrandHeaderLogo />}

          {isLoggedIn ? <DynamicLoggedInHeader /> : <LoggedOutHeader className="logged-out-only" />}
        </div>
      </div>
      <PageContentWrapper className={classes.headerTagContainer}>
        <Txt component="button" className={classes.headerTag} onClick={onHeaderTagClick}>
          Over{' '}
          <Txt component="strong" skipTranslation>
            {formatCurrency(4500000000, undefined, true)}
          </Txt>{' '}
          paid to our users
        </Txt>
      </PageContentWrapper>
      <NavigationBar />
    </header>
  );
};

const MemoizedHeader = React.memo(Header);

export default MemoizedHeader;
