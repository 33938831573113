import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

function parseQueryStringArgs(queryString: string) {
  return queryString
    .replace(/^[?]/, '')
    .split('&')
    .reduce((args: Record<string, string>, encodedPair: string) => {
      const [key, value] = encodedPair.split('=');
      return {
        ...args,
        [decodeURIComponent(key)]: decodeURIComponent(value?.replace(/\+/g, ' ')),
      };
    }, {});
}
export default function useQueryStringParams(): NodeJS.Dict<string> {
  const { query } = useRouter();
  const q: ParsedUrlQuery = query;

  return Object.entries(q).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: Array.isArray(v) ? v[0] : v }),
    {},
  );
}
