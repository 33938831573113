/**
 * @generated SignedSource<<2270a5456354f43214644ce9285b8a02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PingPongContainer_pingPong$data = {
  readonly id: string;
  readonly counter: number;
  readonly " $fragmentType": "PingPongContainer_pingPong";
};
export type PingPongContainer_pingPong$key = {
  readonly " $data"?: PingPongContainer_pingPong$data;
  readonly " $fragmentSpreads": FragmentRefs<"PingPongContainer_pingPong">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./AdminPanelRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "PingPongContainer_pingPong",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "counter",
      "storageKey": null
    }
  ],
  "type": "PingPongCounter",
  "abstractKey": null
};

(node as any).hash = "3fd70820d3f457c54ecfca85d4bb06a3";

export default node;
