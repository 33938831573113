import React from 'react';
import { AlertType, useAlert } from 'react-alert';
import { alertMessageTimeout } from '../components/molecules/AlertMessage/alertMessageTimeout';
import useQueryStringParams from './useQueryStringParams';

function parseAlertType(type: string): AlertType {
  switch (type) {
    case 'success':
      return 'success';
    case 'info':
      return 'info';
    case 'error':
      return 'error';
    default:
      return 'info';
  }
}

export default function useQueryStringAlert() {
  const { message, messageStatus, qmee_survey_result } = useQueryStringParams();
  const alert = useAlert();

  React.useEffect(() => {
    if (message && messageStatus && !qmee_survey_result) {
      alert.show(message, {
        type: parseAlertType(messageStatus),
        timeout: alertMessageTimeout(message),
      });
    }
  }, [message, messageStatus, qmee_survey_result, alert]);
}
