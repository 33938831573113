import { AUTHENTICATION_URL } from '../../../apiUrls';
import { UserSourceData } from '../../../hooks/useUserSourceData';
import { notifyAirbrake } from '../../molecules/ErrorBoundary/ErrorBoundary';
import { SESSION_ID } from './sessionId';
import {
  AuthenticationEmailUpdateResponse,
  AuthenticationPasswordUpdateResponse,
  AuthenticationTokenState,
  LoginError,
  RequestPasswordResetError,
  ResetPasswordError,
  UpdateAccountError,
  UserData,
} from './Types';

export function isTokenExpired(token: string) {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 < new Date().getTime();
  } catch (e) {
    return false;
  }
}
export function getUserDataFromToken(accessToken: string | undefined): UserData | null {
  if (!accessToken) {
    return null;
  }
  try {
    const payload = JSON.parse(atob(accessToken.split('.')[1]));
    return {
      guid: payload.guid || payload.sub,
      roles: payload.roles || [],
      country: payload.country || '',
      currency: payload.currency || '',
    };
  } catch (e) {
    return null;
  }
}

// If a user tries to login too many times they might get blocked and redirected to a very slow domain to slow down attackers.
// This will cause a 303 response without any CORS headers. Because there is no CORS headers the request gets blocked and raises an error instead of returning a response with a 303.
async function throttleResilientFetch(input: RequestInfo, init?: RequestInit | undefined) {
  try {
    return await fetch(input, init);
  } catch (err) {
    console.error('Fetch error: ', err);
    throw new LoginError(
      'Too many login attempts. You have been temporarily blocked for security reasons.',
      303,
    );
  }
}

export async function handleTokenResponse(response: Response): Promise<AuthenticationTokenState> {
  if (response.status === 200) {
    const json = await response.json();
    return {
      refreshToken: json.refreshToken,
      accessToken: json.accessToken,
    };
  }
  if (response.status === 400) {
    const json = await response.json();
    throw new LoginError(json.error, 401);
  } else if (response.status === 401) {
    const json = await response.json();
    throw new LoginError(json.error, 401);
  } else if (response.status === 429 || response.status === 403 || response.status === 303) {
    throw new LoginError(
      'Too many login attempts. Double check the email address in the email field is correct, and try again in a few minutes.',
      response.status,
    );
  } else {
    const body = await response.text();
    notifyAirbrake({
      error: new Error(`Unexpected login error (${response.status}): ${body}`),
      params: { body },
    });
    // eslint-disable-next-line no-console
    console.warn(`Unexpected login error (${response.status}): ${body}`);
    throw new LoginError(
      'Some unexpected low level error occured. Please try again',
      response.status,
    );
  }
}

const HEADERS: Record<string, string> =
  process.env.NEXT_PUBLIC_DEV_SECRET && !AUTHENTICATION_URL?.includes('localhost')
    ? { 'Content-Type': 'application/json', 'Dev-Secret': process.env.NEXT_PUBLIC_DEV_SECRET }
    : { 'Content-Type': 'application/json' };

export async function submitEmailChange(
  refreshToken: string,
  email: string,
  password: string,
): Promise<AuthenticationEmailUpdateResponse> {
  const response = await throttleResilientFetch(`${AUTHENTICATION_URL}/user/email`, {
    method: 'PUT',
    headers: {
      ...HEADERS,
      Authorization: `Bearer ${refreshToken}`,
    },
    credentials: 'include',
    body: JSON.stringify({
      user: {
        email,
        current_password: password,
      },
    }),
  });
  const json = await response.json();
  if (response.status >= 200 && response.status <= 206) {
    return {
      message: json.message,
      newEmail: email,
    };
  }
  if (response.status > 400) {
    throw new UpdateAccountError(json.error, response.status);
  }
  throw new UpdateAccountError('Unable to process update response', response.status);
}

export async function submitResetPasswordRequest(email: string) {
  const response = await throttleResilientFetch(
    `${AUTHENTICATION_URL}/user/password/request-reset`,
    {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify({
        user: { email },
      }),
    },
  );
  const json = await response.json();
  if (response.status >= 200 && response.status <= 206) {
    return {
      message: json.message,
    };
  }
  if (response.status > 400) {
    throw new RequestPasswordResetError(json.error, response.status);
  }
  throw new RequestPasswordResetError(
    'We are unable to request a reset of your password at the moment. Please try again later.',
    response.status,
  );
}

export async function submitResetPassword(
  token: string,
  password: string,
  passwordConfirmation: string,
) {
  const response = await throttleResilientFetch(`${AUTHENTICATION_URL}/user/password/reset`, {
    method: 'POST',
    headers: HEADERS,
    credentials: 'include',
    body: JSON.stringify({
      user: {
        reset_password_token: token,
        password,
        password_confirmation: passwordConfirmation,
      },
    }),
  });
  const json = await response.json();
  if (response.status >= 200 && response.status <= 206) {
    return {
      refreshToken: json.refreshToken,
      accessToken: json.accessToken,
    };
  }
  if (response.status > 400) {
    throw new ResetPasswordError(json.error, response.status);
  }
  throw new ResetPasswordError(
    'We are unable to reset your password at the moment. Please try again later.',
    response.status,
  );
}

export async function submitPasswordChange(
  refreshToken: string,
  currentPassword: string,
  password: string,
  passwordConfirmation: string,
): Promise<AuthenticationPasswordUpdateResponse> {
  const response = await throttleResilientFetch(`${AUTHENTICATION_URL}/user/password`, {
    method: 'PUT',
    headers: {
      ...HEADERS,
      Authorization: `Bearer ${refreshToken}`,
    },
    credentials: 'include',
    body: JSON.stringify({
      user: {
        current_password: currentPassword,
        password,
        password_confirmation: passwordConfirmation,
      },
    }),
  });
  const json = await response.json();
  if (response.status >= 200 && response.status <= 206) {
    return {
      message: json.message,
    };
  }
  if (response.status > 400) {
    throw new UpdateAccountError(json.error, response.status);
  }
  throw new UpdateAccountError('Unable to process update response', response.status);
}

export async function submitSignUp(
  email: string,
  password: string,
  marketing: boolean,
  source: UserSourceData,
): Promise<AuthenticationTokenState> {
  const response = await throttleResilientFetch(`${AUTHENTICATION_URL}/user`, {
    method: 'POST',
    headers: HEADERS,
    credentials: 'include',
    body: JSON.stringify({
      user: {
        email,
        password,
      },
      marketing_email_consent: marketing,
      source: {
        referral_invite_token: source.inviteToken,
        utm_source: source.utmSource,
        utm_medium: source.utmMedium,
        utm_campaign: source.utmCampaign,
        utm_content: source.utmContent,
      },
    }),
  });
  return handleTokenResponse(response);
}

export async function submitLogin(
  email: string,
  password: string,
): Promise<AuthenticationTokenState> {
  const response = await throttleResilientFetch(
    `${AUTHENTICATION_URL}/user/session?id=${SESSION_ID}`,
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify({
        user: {
          email,
          password,
        },
      }),
    },
  );
  return handleTokenResponse(response);
}

export async function submitLoginToken(token: string): Promise<AuthenticationTokenState> {
  const response = await throttleResilientFetch(
    `${AUTHENTICATION_URL}/user/session?login_token=${token}&id=${SESSION_ID}`,
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
    },
  );
  return handleTokenResponse(response);
}

export async function submitConfirmationToken(token: string): Promise<AuthenticationTokenState> {
  const response = await throttleResilientFetch(
    `${AUTHENTICATION_URL}/user/confirm?confirmation_token=${token}&id=${SESSION_ID}`,
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
    },
  );
  if (response.status === 201 || response.status === 204) {
    return null;
  }
  return handleTokenResponse(response);
}

export async function logOut(refreshToken: string | undefined, reason: string): Promise<void> {
  const response = await throttleResilientFetch(
    `${AUTHENTICATION_URL}/user/session?trigger=${reason}&id=${SESSION_ID}`,
    {
      method: 'DELETE',
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${refreshToken}`,
      },
      credentials: 'include',
    },
  );
  if (response.status !== 200 && response.status !== 204) {
    try {
      const json = await response.json();
      // eslint-disable-next-line no-console
      console.warn(`Log out error (${response.status}):`, json);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Log out error (${response.status})`);
    }
  }
}

export async function fetchNewTokens(
  refreshToken: string,
  reason: string,
): Promise<AuthenticationTokenState> {
  const response = await throttleResilientFetch(
    `${AUTHENTICATION_URL}/user/session?id=${SESSION_ID}&reason=${reason}`,
    {
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${refreshToken}`,
      },
      credentials: 'include',
    },
  );
  return handleTokenResponse(response);
}
