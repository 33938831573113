import { TranslationOptions } from 'hooks/useTranslate';

export interface TranslationTemplate {
  template: string;
  patterns: string[];
  complexElements: any[];
}
type ComplexText = (string | any)[];

export function converteToTemplate(elements: ComplexText): TranslationTemplate {
  let count = 0;
  const complexElements: any[] = [];
  const patterns: string[] = [];

  elements.forEach((element) => {
    if (typeof element === 'string' && element.match(/%[0-9]+s;/)) {
      count += 1;
    }
  });

  const template = elements
    .map((child: string | any) => {
      if (typeof child === 'string') {
        return child;
      }
      count += 1;
      complexElements.push(child);
      const pattern = `%${count}s;`;
      patterns.push(pattern);
      return pattern;
    })
    ?.join('');
  return { template, patterns, complexElements };
}

function replacePattern(text: ComplexText, pattern: string, complexElement: any): ComplexText {
  return text.reduce((acc, textElement) => {
    if (typeof textElement === 'string') {
      const elements = textElement.split(pattern);
      const joinedElements: any[] = [];
      elements.forEach((el) => {
        if (joinedElements.length > 0) {
          joinedElements.push(complexElement);
        }
        joinedElements.push(el);
      });
      return acc.concat(joinedElements);
    }
    return acc.concat([textElement]);
  }, []);
}
export function applyTemplate(template: TranslationTemplate): ComplexText {
  let filledText: ComplexText = [template.template];
  for (let i = 0; i < Math.min(template.patterns.length, template.complexElements.length); i += 1) {
    filledText = replacePattern(filledText, template.patterns[i], template.complexElements[i]);
  }

  return filledText;
}

export function translateComplexText(
  elements: ComplexText | string | any,
  translate: (text: string, options?: TranslationOptions) => string,
  options?: TranslationOptions,
) {
  if (typeof elements === 'string') {
    return translate(elements, options);
  }
  if (elements && Array.isArray(elements)) {
    const template = converteToTemplate(elements);
    return applyTemplate({
      ...template,
      template: translate(template.template, options),
    });
  }
  return elements;
}
