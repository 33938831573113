import clsx from 'clsx';
import React from 'react';
import classes from './AlertMessage.module.scss';
import { AlertTemplateProps } from 'react-alert';
import CloseIcon from '../../assets/cross.svg';
import SuccessIcon from '../../assets/success.svg';
import InfoIcon from '../../assets/info.svg';
import Txt from '../../atoms/Txt/Txt';

export type Props = AlertTemplateProps;

const AlertMessage: React.FC<Props> = ({ style, options, message, close }: Props) => {
  return (
    <div style={style} className={clsx(classes.root, options.type && classes[options.type])}>
      {options.type === 'info' && <InfoIcon className={classes.infoIcon} />}
      {options.type === 'success' && <SuccessIcon className={classes.successIcon} />}
      {options.type === 'error' && <InfoIcon className={classes.errorIcon} />}
      <Txt component="p">{message}</Txt>
      <button type="button" onClick={close}>
        <CloseIcon className={classes.closeIcon} />
      </button>
    </div>
  );
};
export default AlertMessage;
