export interface AuthenticationTokens {
  accessToken: string;
  refreshToken: string;
}

export type AuthenticationPasswordUpdateResponse = {
  message?: string;
};

export type AuthenticationEmailUpdateResponse = {
  message?: string;
  newEmail: string;
};
export interface UserData {
  guid: string;
  roles: string[];
  country: string;
  currency: string;
}
type LoginResult = UserData | null;

export type SignUp = (email: string, password: string, marketing: boolean) => Promise<LoginResult>;
export type AuthenticationTokenState = AuthenticationTokens | null;
export type LoginWithPassword = (email: string, password: string) => Promise<LoginResult>;
export type LoginWithToken = (token: string) => Promise<LoginResult>;
export type UpdateTokens = (updatedTokens: AuthenticationTokenState) => void;

export interface AuthenticationContextValue {
  signUp: SignUp;
  getAccessToken: (forceReload?: string) => Promise<string | null>;
  login: LoginWithPassword;
  loginWithToken: LoginWithToken;
  updateTokens: UpdateTokens;
  isLoggedIn: boolean;
  userData: UserData | null;
  refreshToken: string | null;
  logOut: (trigger: string) => void;
}

/* eslint max-classes-per-file: ["error", 4] */
export class UpdateAccountError extends Error {
  updateError: string;

  status?: number;

  constructor(message: string, status: number | undefined = undefined) {
    super(message);
    this.updateError = message;
    this.name = 'UpdateAccountError';
    this.status = status;
  }
}

export class RequestPasswordResetError extends Error {
  updateError: string;

  status?: number;

  constructor(message: string, status: number | undefined = undefined) {
    super(message);
    this.updateError = message;
    this.name = 'RequestPasswordResetError';
    this.status = status;
  }
}
export class ResetPasswordError extends Error {
  updateError: string;

  status?: number;

  constructor(message: string, status: number | undefined = undefined) {
    super(message);
    this.updateError = message;
    this.name = 'ResetPasswordError';
    this.status = status;
  }
}

export class LoginError extends Error {
  loginError: string;

  status?: number;

  constructor(message: string, status: number | undefined = undefined) {
    super(message);
    this.loginError = message;
    this.name = 'LoginError';
    this.status = status;
  }
}

export const TOKENS_STORAGE_KEY = 'qmee.tokens';
