import React, { Suspense } from 'react';
import Cookies from 'js-cookie';
import CookieDialog, { CookieSettingsData } from '../../organisms/CookieDialog/CookieDialog';
import useUserSourceData from '../../../hooks/useUserSourceData';
import useIsSsr from 'hooks/useIsSsr';
import dynamic from 'next/dynamic';

function getTrackingAllowed(cookieSettings: CookieSettingsData) {
  return cookieSettings?.settings.includes('tra');
}
function getMarketingAllowed(cookieSettings: CookieSettingsData) {
  return cookieSettings?.settings.includes('mar');
}
function getSettingsAllowed(cookieSettings: CookieSettingsData) {
  return cookieSettings?.settings.includes('set');
}
function getSocialAllowed(cookieSettings: CookieSettingsData) {
  return cookieSettings?.settings.includes('soc');
}
function getShowCookieBanner(cookieSettings: CookieSettingsData) {
  if (typeof window !== 'undefined') {
    // Client-side-only code
    return !cookieSettings && window.self === window.top; // if there are no settings AND not in an iFrame
  }
  return false;
}
interface TrackingContextValue {
  trackingAllowed: boolean;
  marketingAllowed: boolean;
  settingsAllowed: boolean;
  socialAllowed: boolean;
  shouldShowCookieOverlay: boolean;
}

export function readSettingsFromCookies() {
  const rawCookieData = Cookies.get('cookie-consent');
  if (rawCookieData) {
    return JSON.parse(rawCookieData);
  }
  return null;
}

export const TrackingContext = React.createContext<TrackingContextValue>({
  trackingAllowed: false,
  marketingAllowed: false,
  settingsAllowed: false,
  socialAllowed: false,
  shouldShowCookieOverlay: false,
});

export function clearCookieConsent() {
  Cookies.remove('cookie-consent');
  window.location.reload();
}

export function saveSettingsCookie(settingsData: CookieSettingsData) {
  Cookies.set('cookie-consent', JSON.stringify(settingsData), {
    secure: window.location.protocol === 'https:',
    expires: 365,
  });
}

export function isBot() {
  return /bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent);
}

export interface Props {
  children: React.ReactNode;
}

const LazyCookieDialog = dynamic(() => import('components/organisms/CookieDialog/CookieDialog'));
const TrackingProvider: React.FC<Props> = ({ children }) => {
  useUserSourceData();
  const isSsr = useIsSsr();
  const [cookieSettings, setCookieSettings] = React.useState(readSettingsFromCookies);

  const contextValue = React.useMemo<TrackingContextValue>(
    () => ({
      trackingAllowed: getTrackingAllowed(cookieSettings),
      marketingAllowed: getMarketingAllowed(cookieSettings),
      settingsAllowed: getSettingsAllowed(cookieSettings),
      socialAllowed: getSocialAllowed(cookieSettings),
      shouldShowCookieOverlay: getShowCookieBanner(cookieSettings),
    }),
    [cookieSettings],
  );

  function handleSaveSettingsCookie(settingsData: CookieSettingsData) {
    saveSettingsCookie(settingsData);
    setCookieSettings(readSettingsFromCookies());
  }

  return (
    <TrackingContext.Provider value={contextValue}>
      {children}
      <Suspense fallback={null}>
        {Boolean(contextValue.shouldShowCookieOverlay && !isBot() && !isSsr) && (
          <LazyCookieDialog
            trackingCookiePresent={contextValue.trackingAllowed}
            marketingCookiePresent={contextValue.marketingAllowed}
            settingsCookiePresent={contextValue.settingsAllowed}
            socialCookiePresent={contextValue.socialAllowed}
            saveSettings={handleSaveSettingsCookie}
          />
        )}
      </Suspense>
    </TrackingContext.Provider>
  );
};

export default TrackingProvider;

export function useTrackingAllowed() {
  const context = React.useContext<TrackingContextValue>(TrackingContext);
  return context.trackingAllowed;
}

export function useMarketingAllowed() {
  const context = React.useContext<TrackingContextValue>(TrackingContext);
  return context.marketingAllowed;
}
export function useSocialAllowed() {
  const context = React.useContext<TrackingContextValue>(TrackingContext);
  return context.socialAllowed;
}
