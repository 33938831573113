import React, { useEffect, useState } from 'react';
import classes from './NavigationBar.module.scss';
import clsx from 'clsx';

import A from '../../atoms/A/A';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Txt from '../../atoms/Txt/Txt';
import { useRouter } from 'next/router';
import {
  BLOG_POSTS,
  BROWSER_APP,
  CASHBACK,
  DASHBOARD,
  DEALS,
  GAMING,
  SURVEYS,
  PUBLIC_SURVEYS,
  BROWSER_APP_INSTALLED,
} from 'components/pages/pages';
import useIsSsr from 'hooks/useIsSsr';
import { useIsLoggedIn } from 'components/templates/AuthenticationProvider/AuthenticationProvider';

export interface Props {
  isHidden?: boolean;
}
const PAGES = [
  DASHBOARD,
  SURVEYS,
  PUBLIC_SURVEYS,
  CASHBACK,
  DEALS,
  GAMING,
  BLOG_POSTS,
  BROWSER_APP,
  BROWSER_APP_INSTALLED,
];

const NavigationBar: React.FC<Props> = ({ isHidden }) => {
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();

  const pages = React.useMemo(() => {
    const browserAppSupported = router.locale ? router.locale?.startsWith('en') : false;

    return PAGES.filter((page) => {
      if (page === BROWSER_APP_INSTALLED) {
        return false;
      } else if (page === SURVEYS && !isLoggedIn) {
        return false;
      } else if (page === PUBLIC_SURVEYS && isLoggedIn) {
        return false;
      } else if (page === BROWSER_APP && !browserAppSupported) {
        return false;
      } else {
        return true;
      }
    });
  }, [router.locale, isLoggedIn]);

  const isSsr = useIsSsr();
  const { width } = useWindowDimensions();
  const [hiddenTabs, setHiddenTabs] = useState(Number);

  useEffect(() => {
    if (isSsr) {
    } else if (width > 625) {
      const overflow = 0;
      if (hiddenTabs !== overflow) {
        setHiddenTabs(overflow);
      }
    } else if (width >= 516 && width <= 625) {
      const overflow = 1;
      if (hiddenTabs !== overflow) {
        setHiddenTabs(overflow);
      }
    } else if (width <= 515) {
      const overflow = 2;
      if (hiddenTabs !== overflow) {
        setHiddenTabs(overflow);
      }
    }
  }, [width, hiddenTabs, isSsr]);

  const cleanedPath = router.pathname.replace(/\/$/, '');

  return (
    <div className={clsx(classes.root, isHidden && classes.isHidden)}>
      <nav className={classes.content}>
        {pages.map((page) => {
          return (
            <A
              key={page.path}
              to={page.path}
              className={clsx(
                classes.link,
                cleanedPath === page.path && classes.activeLink,
                page === DASHBOARD && 'logged-in-only',
              )}
            >
              <Txt>{page.name}</Txt>
              {/* Hack to prevent the width from changing on hover */}
              <p>{page.name}</p>
            </A>
          );
        })}
      </nav>
      <div className={clsx(classes.sideScrollFade, classes[`hiddenTabs${hiddenTabs}`])} />
    </div>
  );
};

export default NavigationBar;
